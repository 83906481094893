export const COOKIE_NAME: string = 'typoza-session'

export const ARCHIVE_POSTS_PER_PAGE: number = 12
export const AUTHOR_POSTS_PER_PAGE: number = 9

export const HOMEPAGE_POSTS_PER_PAGE_BY_THEME: {
  [key: string]: {
    init: number
    load: number
  }
} = {
  default: {
    init: 11,
    load: 10,
  },
  minimal: {
    init: 8,
    load: 10,
  },
  lifestyle: {
    init: 12,
    load: 10,
  },
  alternate: {
    init: 10,
    load: 10,
  },
  grid: {
    init: 11,
    load: 10,
  },
}

export const HOME_PAGE_PATH: string = '/'
export const POST_PAGE_PATH: string = '/'
export const CATEGORY_PAGE_PATH: string = '/categories/'
export const LABEL_PAGE_PATH: string = '/labels/'
export const PROFILE_PAGE_PATH: string = '/authors/'
export const ARCHIVE_PAGE_PATH: string = '/archive'
export const SEARCH_PAGE_PATH: string = '/search'
export const LOGIN_PAGE_PATH: string = '/login'
export const REGISTER_PAGE_PATH: string = '/register'

export const OFFICIAL_BLOG_URL: string = 'https://blog.typoza.com'

export const DEFAULT_BLOG_CONTENT: {
  name: string
  slug: string
  description: string
  color: string
  subject: string
  posts: {
    title: string
    slug: string
    paragraphs: string[]
    thumbnailUrl: string
    thumbnailColor: string
    thumbnailAlt: string
    featured?: boolean
  }[]
}[] = [
  {
    name: 'Technology',
    slug: 'technology',
    description:
      'Articles about the latest trends and innovations in technology.',
    color: 'blue',
    subject: 'technology',
    posts: [
      {
        title: `Lorem ipsum dolor sit amet, consectetur adipiscing elit`,
        slug: 'lorem-ipsum-dolor-sit-amet',
        paragraphs: [
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed nisi mauris. Sed finibus nec dui in scelerisque. Aliquam a convallis leo, id blandit nisi. Phasellus dui libero, cursus in dui a, congue sagittis metus. Integer posuere lacus ut efficitur porttitor. Duis in nulla eu risus eleifend egestas. Donec viverra ultrices aliquet. Suspendisse facilisis quis ante ac commodo. Donec bibendum sodales purus non vehicula. Quisque dignissim augue ut ipsum tincidunt, id efficitur nibh feugiat. Ut non nisi arcu.',
          'Ut diam erat, consequat quis posuere vel, dignissim id lectus. Aliquam sollicitudin ante ut diam convallis accumsan. Curabitur eu sapien vitae enim imperdiet tempus. Aliquam iaculis sodales consequat. Maecenas faucibus sem ut facilisis pulvinar. Mauris pulvinar diam eget eros suscipit convallis sed molestie erat. Ut tristique suscipit turpis, eu tempus turpis volutpat non. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nullam blandit, libero quis sagittis accumsan, ligula odio tristique ex, eleifend blandit urna justo sit amet ante. Mauris non vestibulum turpis.',
          'Nam congue molestie libero sed bibendum. Fusce posuere eros ac mauris dapibus pharetra eu convallis felis. Etiam pellentesque ultricies rhoncus. Duis fermentum orci sem, nec tincidunt nibh viverra eu. Aenean dictum accumsan dictum. Nulla sit amet ipsum quam. Cras eu neque maximus, vulputate diam eu, consequat enim. Aliquam erat volutpat. Phasellus et rutrum magna.',
          'Vestibulum blandit nunc non sollicitudin imperdiet. In ultrices mauris eu nisl molestie elementum. In vel erat enim. Duis non magna sem. Quisque et egestas neque, vitae fermentum ante. Maecenas venenatis nibh eu facilisis egestas. Praesent iaculis rhoncus sodales. Aenean feugiat lectus nec tortor bibendum lobortis. Quisque velit nulla, pellentesque ac accumsan sed, sollicitudin id urna. Fusce ut dui urna. Phasellus dictum eget arcu vel tempor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi gravida non dolor quis aliquam. In hac habitasse platea dictumst. Mauris convallis ex at massa finibus, ac feugiat diam vestibulum. Phasellus ultricies est orci, suscipit elementum arcu dictum at.',
          'Sed quis tortor urna. Cras et eleifend arcu, nec semper sapien. Nunc vel facilisis turpis. Donec ullamcorper augue at nunc sollicitudin, quis consectetur velit accumsan. Sed quis pretium felis. Pellentesque consequat est a tempor ullamcorper. Quisque ullamcorper nisl in nisl laoreet, id tincidunt ligula posuere. Nam in auctor nibh. Nunc lobortis posuere tellus, malesuada posuere mi tempus eget. Quisque ut magna non risus faucibus commodo. In hac habitasse platea dictumst. Cras ut massa nisi.',
        ],
        thumbnailUrl:
          'https://stablo-pro.web3templates.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fcijrdavx%2Fproduction%2F05951a0ec1a6ffc54f615ab160649e92fea982d0-800x764.png%3Frect%3D0%2C0%2C800%2C468%26w%3D800%26auto%3Dformat&w=1200&q=75',
        thumbnailColor: '#2770cf',
        thumbnailAlt: 'Thumbnail',
        featured: true,
      },
      {
        title: `Sed sit amet nulla lacus`,
        slug: 'sed-sit-amet-nulla-lacus',
        paragraphs: [
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed nisi mauris. Sed finibus nec dui in scelerisque. Aliquam a convallis leo, id blandit nisi. Phasellus dui libero, cursus in dui a, congue sagittis metus. Integer posuere lacus ut efficitur porttitor. Duis in nulla eu risus eleifend egestas. Donec viverra ultrices aliquet. Suspendisse facilisis quis ante ac commodo. Donec bibendum sodales purus non vehicula. Quisque dignissim augue ut ipsum tincidunt, id efficitur nibh feugiat. Ut non nisi arcu.',
          'Ut diam erat, consequat quis posuere vel, dignissim id lectus. Aliquam sollicitudin ante ut diam convallis accumsan. Curabitur eu sapien vitae enim imperdiet tempus. Aliquam iaculis sodales consequat. Maecenas faucibus sem ut facilisis pulvinar. Mauris pulvinar diam eget eros suscipit convallis sed molestie erat. Ut tristique suscipit turpis, eu tempus turpis volutpat non. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nullam blandit, libero quis sagittis accumsan, ligula odio tristique ex, eleifend blandit urna justo sit amet ante. Mauris non vestibulum turpis.',
          'Nam congue molestie libero sed bibendum. Fusce posuere eros ac mauris dapibus pharetra eu convallis felis. Etiam pellentesque ultricies rhoncus. Duis fermentum orci sem, nec tincidunt nibh viverra eu. Aenean dictum accumsan dictum. Nulla sit amet ipsum quam. Cras eu neque maximus, vulputate diam eu, consequat enim. Aliquam erat volutpat. Phasellus et rutrum magna.',
          'Vestibulum blandit nunc non sollicitudin imperdiet. In ultrices mauris eu nisl molestie elementum. In vel erat enim. Duis non magna sem. Quisque et egestas neque, vitae fermentum ante. Maecenas venenatis nibh eu facilisis egestas. Praesent iaculis rhoncus sodales. Aenean feugiat lectus nec tortor bibendum lobortis. Quisque velit nulla, pellentesque ac accumsan sed, sollicitudin id urna. Fusce ut dui urna. Phasellus dictum eget arcu vel tempor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi gravida non dolor quis aliquam. In hac habitasse platea dictumst. Mauris convallis ex at massa finibus, ac feugiat diam vestibulum. Phasellus ultricies est orci, suscipit elementum arcu dictum at.',
          'Sed quis tortor urna. Cras et eleifend arcu, nec semper sapien. Nunc vel facilisis turpis. Donec ullamcorper augue at nunc sollicitudin, quis consectetur velit accumsan. Sed quis pretium felis. Pellentesque consequat est a tempor ullamcorper. Quisque ullamcorper nisl in nisl laoreet, id tincidunt ligula posuere. Nam in auctor nibh. Nunc lobortis posuere tellus, malesuada posuere mi tempus eget. Quisque ut magna non risus faucibus commodo. In hac habitasse platea dictumst. Cras ut massa nisi.',
        ],
        thumbnailUrl:
          'https://stablo-pro.web3templates.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fcijrdavx%2Fproduction%2F2fda477a7e32f813abb9a8ef425939e6a91c7973-987x1481.png%3Frect%3D0%2C279%2C987%2C607%26w%3D987%26auto%3Dformat&w=1200&q=75',
        thumbnailColor: '#2770cf',
        thumbnailAlt: 'Thumbnail',
      },
      {
        title: `Fusce posuere eros ac mauris dapibus pharetra`,
        slug: 'fusce-posuere-eros-ac-mauris-dapibus-pharetra',
        paragraphs: [
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed nisi mauris. Sed finibus nec dui in scelerisque. Aliquam a convallis leo, id blandit nisi. Phasellus dui libero, cursus in dui a, congue sagittis metus. Integer posuere lacus ut efficitur porttitor. Duis in nulla eu risus eleifend egestas. Donec viverra ultrices aliquet. Suspendisse facilisis quis ante ac commodo. Donec bibendum sodales purus non vehicula. Quisque dignissim augue ut ipsum tincidunt, id efficitur nibh feugiat. Ut non nisi arcu.',
          'Ut diam erat, consequat quis posuere vel, dignissim id lectus. Aliquam sollicitudin ante ut diam convallis accumsan. Curabitur eu sapien vitae enim imperdiet tempus. Aliquam iaculis sodales consequat. Maecenas faucibus sem ut facilisis pulvinar. Mauris pulvinar diam eget eros suscipit convallis sed molestie erat. Ut tristique suscipit turpis, eu tempus turpis volutpat non. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nullam blandit, libero quis sagittis accumsan, ligula odio tristique ex, eleifend blandit urna justo sit amet ante. Mauris non vestibulum turpis.',
          'Nam congue molestie libero sed bibendum. Fusce posuere eros ac mauris dapibus pharetra eu convallis felis. Etiam pellentesque ultricies rhoncus. Duis fermentum orci sem, nec tincidunt nibh viverra eu. Aenean dictum accumsan dictum. Nulla sit amet ipsum quam. Cras eu neque maximus, vulputate diam eu, consequat enim. Aliquam erat volutpat. Phasellus et rutrum magna.',
          'Vestibulum blandit nunc non sollicitudin imperdiet. In ultrices mauris eu nisl molestie elementum. In vel erat enim. Duis non magna sem. Quisque et egestas neque, vitae fermentum ante. Maecenas venenatis nibh eu facilisis egestas. Praesent iaculis rhoncus sodales. Aenean feugiat lectus nec tortor bibendum lobortis. Quisque velit nulla, pellentesque ac accumsan sed, sollicitudin id urna. Fusce ut dui urna. Phasellus dictum eget arcu vel tempor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi gravida non dolor quis aliquam. In hac habitasse platea dictumst. Mauris convallis ex at massa finibus, ac feugiat diam vestibulum. Phasellus ultricies est orci, suscipit elementum arcu dictum at.',
          'Sed quis tortor urna. Cras et eleifend arcu, nec semper sapien. Nunc vel facilisis turpis. Donec ullamcorper augue at nunc sollicitudin, quis consectetur velit accumsan. Sed quis pretium felis. Pellentesque consequat est a tempor ullamcorper. Quisque ullamcorper nisl in nisl laoreet, id tincidunt ligula posuere. Nam in auctor nibh. Nunc lobortis posuere tellus, malesuada posuere mi tempus eget. Quisque ut magna non risus faucibus commodo. In hac habitasse platea dictumst. Cras ut massa nisi.',
        ],
        thumbnailUrl:
          'https://stablo-pro.web3templates.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fcijrdavx%2Fproduction%2Fa5bd8977e7fd82666c00a45948583b1f9912d392-3847x5583.jpg%3Fw%3D2000%26auto%3Dformat&w=1200&q=75',
        thumbnailColor: '#2770cf',
        thumbnailAlt: 'Thumbnail',
      },
      {
        title: `Nulla sit amet ipsum quam`,
        slug: 'nulla-sit-amet-ipsum-quam',
        paragraphs: [
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed nisi mauris. Sed finibus nec dui in scelerisque. Aliquam a convallis leo, id blandit nisi. Phasellus dui libero, cursus in dui a, congue sagittis metus. Integer posuere lacus ut efficitur porttitor. Duis in nulla eu risus eleifend egestas. Donec viverra ultrices aliquet. Suspendisse facilisis quis ante ac commodo. Donec bibendum sodales purus non vehicula. Quisque dignissim augue ut ipsum tincidunt, id efficitur nibh feugiat. Ut non nisi arcu.',
          'Ut diam erat, consequat quis posuere vel, dignissim id lectus. Aliquam sollicitudin ante ut diam convallis accumsan. Curabitur eu sapien vitae enim imperdiet tempus. Aliquam iaculis sodales consequat. Maecenas faucibus sem ut facilisis pulvinar. Mauris pulvinar diam eget eros suscipit convallis sed molestie erat. Ut tristique suscipit turpis, eu tempus turpis volutpat non. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nullam blandit, libero quis sagittis accumsan, ligula odio tristique ex, eleifend blandit urna justo sit amet ante. Mauris non vestibulum turpis.',
          'Nam congue molestie libero sed bibendum. Fusce posuere eros ac mauris dapibus pharetra eu convallis felis. Etiam pellentesque ultricies rhoncus. Duis fermentum orci sem, nec tincidunt nibh viverra eu. Aenean dictum accumsan dictum. Nulla sit amet ipsum quam. Cras eu neque maximus, vulputate diam eu, consequat enim. Aliquam erat volutpat. Phasellus et rutrum magna.',
          'Vestibulum blandit nunc non sollicitudin imperdiet. In ultrices mauris eu nisl molestie elementum. In vel erat enim. Duis non magna sem. Quisque et egestas neque, vitae fermentum ante. Maecenas venenatis nibh eu facilisis egestas. Praesent iaculis rhoncus sodales. Aenean feugiat lectus nec tortor bibendum lobortis. Quisque velit nulla, pellentesque ac accumsan sed, sollicitudin id urna. Fusce ut dui urna. Phasellus dictum eget arcu vel tempor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi gravida non dolor quis aliquam. In hac habitasse platea dictumst. Mauris convallis ex at massa finibus, ac feugiat diam vestibulum. Phasellus ultricies est orci, suscipit elementum arcu dictum at.',
          'Sed quis tortor urna. Cras et eleifend arcu, nec semper sapien. Nunc vel facilisis turpis. Donec ullamcorper augue at nunc sollicitudin, quis consectetur velit accumsan. Sed quis pretium felis. Pellentesque consequat est a tempor ullamcorper. Quisque ullamcorper nisl in nisl laoreet, id tincidunt ligula posuere. Nam in auctor nibh. Nunc lobortis posuere tellus, malesuada posuere mi tempus eget. Quisque ut magna non risus faucibus commodo. In hac habitasse platea dictumst. Cras ut massa nisi.',
        ],
        thumbnailUrl:
          'https://stablo-pro.web3templates.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fcijrdavx%2Fproduction%2F279c03681911845947cd044b4ac9e91d7a3a628c-987x1481.png%3Fw%3D987%26auto%3Dformat&w=1200&q=75',
        thumbnailColor: '#2770cf',
        thumbnailAlt: 'Thumbnail',
      },
      {
        title: `Cras eu neque maximus, vulputate diam eu, consequat enim`,
        slug: 'cras-eu-neque-maximus-vulputate-diam-eu-consequat-enim',
        paragraphs: [
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed nisi mauris. Sed finibus nec dui in scelerisque. Aliquam a convallis leo, id blandit nisi. Phasellus dui libero, cursus in dui a, congue sagittis metus. Integer posuere lacus ut efficitur porttitor. Duis in nulla eu risus eleifend egestas. Donec viverra ultrices aliquet. Suspendisse facilisis quis ante ac commodo. Donec bibendum sodales purus non vehicula. Quisque dignissim augue ut ipsum tincidunt, id efficitur nibh feugiat. Ut non nisi arcu.',
          'Ut diam erat, consequat quis posuere vel, dignissim id lectus. Aliquam sollicitudin ante ut diam convallis accumsan. Curabitur eu sapien vitae enim imperdiet tempus. Aliquam iaculis sodales consequat. Maecenas faucibus sem ut facilisis pulvinar. Mauris pulvinar diam eget eros suscipit convallis sed molestie erat. Ut tristique suscipit turpis, eu tempus turpis volutpat non. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nullam blandit, libero quis sagittis accumsan, ligula odio tristique ex, eleifend blandit urna justo sit amet ante. Mauris non vestibulum turpis.',
          'Nam congue molestie libero sed bibendum. Fusce posuere eros ac mauris dapibus pharetra eu convallis felis. Etiam pellentesque ultricies rhoncus. Duis fermentum orci sem, nec tincidunt nibh viverra eu. Aenean dictum accumsan dictum. Nulla sit amet ipsum quam. Cras eu neque maximus, vulputate diam eu, consequat enim. Aliquam erat volutpat. Phasellus et rutrum magna.',
          'Vestibulum blandit nunc non sollicitudin imperdiet. In ultrices mauris eu nisl molestie elementum. In vel erat enim. Duis non magna sem. Quisque et egestas neque, vitae fermentum ante. Maecenas venenatis nibh eu facilisis egestas. Praesent iaculis rhoncus sodales. Aenean feugiat lectus nec tortor bibendum lobortis. Quisque velit nulla, pellentesque ac accumsan sed, sollicitudin id urna. Fusce ut dui urna. Phasellus dictum eget arcu vel tempor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi gravida non dolor quis aliquam. In hac habitasse platea dictumst. Mauris convallis ex at massa finibus, ac feugiat diam vestibulum. Phasellus ultricies est orci, suscipit elementum arcu dictum at.',
          'Sed quis tortor urna. Cras et eleifend arcu, nec semper sapien. Nunc vel facilisis turpis. Donec ullamcorper augue at nunc sollicitudin, quis consectetur velit accumsan. Sed quis pretium felis. Pellentesque consequat est a tempor ullamcorper. Quisque ullamcorper nisl in nisl laoreet, id tincidunt ligula posuere. Nam in auctor nibh. Nunc lobortis posuere tellus, malesuada posuere mi tempus eget. Quisque ut magna non risus faucibus commodo. In hac habitasse platea dictumst. Cras ut massa nisi.',
        ],
        thumbnailUrl:
          'https://stablo-pro.web3templates.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fcijrdavx%2Fproduction%2F123fb8836aaf097b83d4c5e8fde544dd1747af0d-5064x3376.jpg%3Fw%3D2000%26auto%3Dformat&w=1200&q=75',
        thumbnailColor: '#2770cf',
        thumbnailAlt: 'Thumbnail',
      },
      {
        title: `Aliquam erat volutpat`,
        slug: 'aliquam-erat-volutpat',
        paragraphs: [
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed nisi mauris. Sed finibus nec dui in scelerisque. Aliquam a convallis leo, id blandit nisi. Phasellus dui libero, cursus in dui a, congue sagittis metus. Integer posuere lacus ut efficitur porttitor. Duis in nulla eu risus eleifend egestas. Donec viverra ultrices aliquet. Suspendisse facilisis quis ante ac commodo. Donec bibendum sodales purus non vehicula. Quisque dignissim augue ut ipsum tincidunt, id efficitur nibh feugiat. Ut non nisi arcu.',
          'Ut diam erat, consequat quis posuere vel, dignissim id lectus. Aliquam sollicitudin ante ut diam convallis accumsan. Curabitur eu sapien vitae enim imperdiet tempus. Aliquam iaculis sodales consequat. Maecenas faucibus sem ut facilisis pulvinar. Mauris pulvinar diam eget eros suscipit convallis sed molestie erat. Ut tristique suscipit turpis, eu tempus turpis volutpat non. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nullam blandit, libero quis sagittis accumsan, ligula odio tristique ex, eleifend blandit urna justo sit amet ante. Mauris non vestibulum turpis.',
          'Nam congue molestie libero sed bibendum. Fusce posuere eros ac mauris dapibus pharetra eu convallis felis. Etiam pellentesque ultricies rhoncus. Duis fermentum orci sem, nec tincidunt nibh viverra eu. Aenean dictum accumsan dictum. Nulla sit amet ipsum quam. Cras eu neque maximus, vulputate diam eu, consequat enim. Aliquam erat volutpat. Phasellus et rutrum magna.',
          'Vestibulum blandit nunc non sollicitudin imperdiet. In ultrices mauris eu nisl molestie elementum. In vel erat enim. Duis non magna sem. Quisque et egestas neque, vitae fermentum ante. Maecenas venenatis nibh eu facilisis egestas. Praesent iaculis rhoncus sodales. Aenean feugiat lectus nec tortor bibendum lobortis. Quisque velit nulla, pellentesque ac accumsan sed, sollicitudin id urna. Fusce ut dui urna. Phasellus dictum eget arcu vel tempor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi gravida non dolor quis aliquam. In hac habitasse platea dictumst. Mauris convallis ex at massa finibus, ac feugiat diam vestibulum. Phasellus ultricies est orci, suscipit elementum arcu dictum at.',
          'Sed quis tortor urna. Cras et eleifend arcu, nec semper sapien. Nunc vel facilisis turpis. Donec ullamcorper augue at nunc sollicitudin, quis consectetur velit accumsan. Sed quis pretium felis. Pellentesque consequat est a tempor ullamcorper. Quisque ullamcorper nisl in nisl laoreet, id tincidunt ligula posuere. Nam in auctor nibh. Nunc lobortis posuere tellus, malesuada posuere mi tempus eget. Quisque ut magna non risus faucibus commodo. In hac habitasse platea dictumst. Cras ut massa nisi.',
        ],
        thumbnailUrl:
          'https://stablo-pro.web3templates.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fcijrdavx%2Fproduction%2F5be1635417f1814b3fb09f8e9f74f37079899f72-4032x3024.jpg%3Fw%3D2000%26auto%3Dformat&w=1200&q=75',
        thumbnailColor: '#2770cf',
        thumbnailAlt: 'Thumbnail',
      },
      {
        title: `Phasellus et rutrum magna`,
        slug: 'phasellus-et-rutrum-magna',
        paragraphs: [
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed nisi mauris. Sed finibus nec dui in scelerisque. Aliquam a convallis leo, id blandit nisi. Phasellus dui libero, cursus in dui a, congue sagittis metus. Integer posuere lacus ut efficitur porttitor. Duis in nulla eu risus eleifend egestas. Donec viverra ultrices aliquet. Suspendisse facilisis quis ante ac commodo. Donec bibendum sodales purus non vehicula. Quisque dignissim augue ut ipsum tincidunt, id efficitur nibh feugiat. Ut non nisi arcu.',
          'Ut diam erat, consequat quis posuere vel, dignissim id lectus. Aliquam sollicitudin ante ut diam convallis accumsan. Curabitur eu sapien vitae enim imperdiet tempus. Aliquam iaculis sodales consequat. Maecenas faucibus sem ut facilisis pulvinar. Mauris pulvinar diam eget eros suscipit convallis sed molestie erat. Ut tristique suscipit turpis, eu tempus turpis volutpat non. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nullam blandit, libero quis sagittis accumsan, ligula odio tristique ex, eleifend blandit urna justo sit amet ante. Mauris non vestibulum turpis.',
          'Nam congue molestie libero sed bibendum. Fusce posuere eros ac mauris dapibus pharetra eu convallis felis. Etiam pellentesque ultricies rhoncus. Duis fermentum orci sem, nec tincidunt nibh viverra eu. Aenean dictum accumsan dictum. Nulla sit amet ipsum quam. Cras eu neque maximus, vulputate diam eu, consequat enim. Aliquam erat volutpat. Phasellus et rutrum magna.',
          'Vestibulum blandit nunc non sollicitudin imperdiet. In ultrices mauris eu nisl molestie elementum. In vel erat enim. Duis non magna sem. Quisque et egestas neque, vitae fermentum ante. Maecenas venenatis nibh eu facilisis egestas. Praesent iaculis rhoncus sodales. Aenean feugiat lectus nec tortor bibendum lobortis. Quisque velit nulla, pellentesque ac accumsan sed, sollicitudin id urna. Fusce ut dui urna. Phasellus dictum eget arcu vel tempor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi gravida non dolor quis aliquam. In hac habitasse platea dictumst. Mauris convallis ex at massa finibus, ac feugiat diam vestibulum. Phasellus ultricies est orci, suscipit elementum arcu dictum at.',
          'Sed quis tortor urna. Cras et eleifend arcu, nec semper sapien. Nunc vel facilisis turpis. Donec ullamcorper augue at nunc sollicitudin, quis consectetur velit accumsan. Sed quis pretium felis. Pellentesque consequat est a tempor ullamcorper. Quisque ullamcorper nisl in nisl laoreet, id tincidunt ligula posuere. Nam in auctor nibh. Nunc lobortis posuere tellus, malesuada posuere mi tempus eget. Quisque ut magna non risus faucibus commodo. In hac habitasse platea dictumst. Cras ut massa nisi.',
        ],
        thumbnailUrl:
          'https://stablo-pro.web3templates.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fcijrdavx%2Fproduction%2Fd4898616e2973584b1b8a0bfdb5c6cf7c36ab012-1064x1330.png%3Fw%3D1064%26auto%3Dformat&w=1200&q=75',
        thumbnailColor: '#2770cf',
        thumbnailAlt: 'Thumbnail',
      },
      {
        title: `Vestibulum blandit nunc non sollicitudin imperdiet`,
        slug: 'vestibulum-blandit-nunc-non-sollicitudin-imperdiet',
        paragraphs: [
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed nisi mauris. Sed finibus nec dui in scelerisque. Aliquam a convallis leo, id blandit nisi. Phasellus dui libero, cursus in dui a, congue sagittis metus. Integer posuere lacus ut efficitur porttitor. Duis in nulla eu risus eleifend egestas. Donec viverra ultrices aliquet. Suspendisse facilisis quis ante ac commodo. Donec bibendum sodales purus non vehicula. Quisque dignissim augue ut ipsum tincidunt, id efficitur nibh feugiat. Ut non nisi arcu.',
          'Ut diam erat, consequat quis posuere vel, dignissim id lectus. Aliquam sollicitudin ante ut diam convallis accumsan. Curabitur eu sapien vitae enim imperdiet tempus. Aliquam iaculis sodales consequat. Maecenas faucibus sem ut facilisis pulvinar. Mauris pulvinar diam eget eros suscipit convallis sed molestie erat. Ut tristique suscipit turpis, eu tempus turpis volutpat non. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nullam blandit, libero quis sagittis accumsan, ligula odio tristique ex, eleifend blandit urna justo sit amet ante. Mauris non vestibulum turpis.',
          'Nam congue molestie libero sed bibendum. Fusce posuere eros ac mauris dapibus pharetra eu convallis felis. Etiam pellentesque ultricies rhoncus. Duis fermentum orci sem, nec tincidunt nibh viverra eu. Aenean dictum accumsan dictum. Nulla sit amet ipsum quam. Cras eu neque maximus, vulputate diam eu, consequat enim. Aliquam erat volutpat. Phasellus et rutrum magna.',
          'Vestibulum blandit nunc non sollicitudin imperdiet. In ultrices mauris eu nisl molestie elementum. In vel erat enim. Duis non magna sem. Quisque et egestas neque, vitae fermentum ante. Maecenas venenatis nibh eu facilisis egestas. Praesent iaculis rhoncus sodales. Aenean feugiat lectus nec tortor bibendum lobortis. Quisque velit nulla, pellentesque ac accumsan sed, sollicitudin id urna. Fusce ut dui urna. Phasellus dictum eget arcu vel tempor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi gravida non dolor quis aliquam. In hac habitasse platea dictumst. Mauris convallis ex at massa finibus, ac feugiat diam vestibulum. Phasellus ultricies est orci, suscipit elementum arcu dictum at.',
          'Sed quis tortor urna. Cras et eleifend arcu, nec semper sapien. Nunc vel facilisis turpis. Donec ullamcorper augue at nunc sollicitudin, quis consectetur velit accumsan. Sed quis pretium felis. Pellentesque consequat est a tempor ullamcorper. Quisque ullamcorper nisl in nisl laoreet, id tincidunt ligula posuere. Nam in auctor nibh. Nunc lobortis posuere tellus, malesuada posuere mi tempus eget. Quisque ut magna non risus faucibus commodo. In hac habitasse platea dictumst. Cras ut massa nisi.',
        ],
        thumbnailUrl:
          'https://stablo-pro.web3templates.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fcijrdavx%2Fproduction%2F86cb3a2f0e43610371a7184483c5567bd9e6e94f-2400x1600.jpg%3Fw%3D2000%26auto%3Dformat&w=1200&q=75',
        thumbnailColor: '#2770cf',
        thumbnailAlt: 'Thumbnail',
      },
      {
        title: `In ultrices mauris eu nisl molestie elementum`,
        slug: 'in-ultrices-mauris-eu-nisl-molestie-elementum',
        paragraphs: [
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed nisi mauris. Sed finibus nec dui in scelerisque. Aliquam a convallis leo, id blandit nisi. Phasellus dui libero, cursus in dui a, congue sagittis metus. Integer posuere lacus ut efficitur porttitor. Duis in nulla eu risus eleifend egestas. Donec viverra ultrices aliquet. Suspendisse facilisis quis ante ac commodo. Donec bibendum sodales purus non vehicula. Quisque dignissim augue ut ipsum tincidunt, id efficitur nibh feugiat. Ut non nisi arcu.',
          'Ut diam erat, consequat quis posuere vel, dignissim id lectus. Aliquam sollicitudin ante ut diam convallis accumsan. Curabitur eu sapien vitae enim imperdiet tempus. Aliquam iaculis sodales consequat. Maecenas faucibus sem ut facilisis pulvinar. Mauris pulvinar diam eget eros suscipit convallis sed molestie erat. Ut tristique suscipit turpis, eu tempus turpis volutpat non. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nullam blandit, libero quis sagittis accumsan, ligula odio tristique ex, eleifend blandit urna justo sit amet ante. Mauris non vestibulum turpis.',
          'Nam congue molestie libero sed bibendum. Fusce posuere eros ac mauris dapibus pharetra eu convallis felis. Etiam pellentesque ultricies rhoncus. Duis fermentum orci sem, nec tincidunt nibh viverra eu. Aenean dictum accumsan dictum. Nulla sit amet ipsum quam. Cras eu neque maximus, vulputate diam eu, consequat enim. Aliquam erat volutpat. Phasellus et rutrum magna.',
          'Vestibulum blandit nunc non sollicitudin imperdiet. In ultrices mauris eu nisl molestie elementum. In vel erat enim. Duis non magna sem. Quisque et egestas neque, vitae fermentum ante. Maecenas venenatis nibh eu facilisis egestas. Praesent iaculis rhoncus sodales. Aenean feugiat lectus nec tortor bibendum lobortis. Quisque velit nulla, pellentesque ac accumsan sed, sollicitudin id urna. Fusce ut dui urna. Phasellus dictum eget arcu vel tempor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi gravida non dolor quis aliquam. In hac habitasse platea dictumst. Mauris convallis ex at massa finibus, ac feugiat diam vestibulum. Phasellus ultricies est orci, suscipit elementum arcu dictum at.',
          'Sed quis tortor urna. Cras et eleifend arcu, nec semper sapien. Nunc vel facilisis turpis. Donec ullamcorper augue at nunc sollicitudin, quis consectetur velit accumsan. Sed quis pretium felis. Pellentesque consequat est a tempor ullamcorper. Quisque ullamcorper nisl in nisl laoreet, id tincidunt ligula posuere. Nam in auctor nibh. Nunc lobortis posuere tellus, malesuada posuere mi tempus eget. Quisque ut magna non risus faucibus commodo. In hac habitasse platea dictumst. Cras ut massa nisi.',
        ],
        thumbnailUrl:
          'https://stablo-pro.web3templates.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fcijrdavx%2Fproduction%2Fe60f8ab265df3c22fdde5469de54d225017b7323-4000x5000.jpg%3Fw%3D2000%26auto%3Dformat&w=1200&q=75',
        thumbnailColor: '#2770cf',
        thumbnailAlt: 'Thumbnail',
      },
      {
        title: `In vel erat enim`,
        slug: 'in-vel-erat-enim',
        paragraphs: [
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed nisi mauris. Sed finibus nec dui in scelerisque. Aliquam a convallis leo, id blandit nisi. Phasellus dui libero, cursus in dui a, congue sagittis metus. Integer posuere lacus ut efficitur porttitor. Duis in nulla eu risus eleifend egestas. Donec viverra ultrices aliquet. Suspendisse facilisis quis ante ac commodo. Donec bibendum sodales purus non vehicula. Quisque dignissim augue ut ipsum tincidunt, id efficitur nibh feugiat. Ut non nisi arcu.',
          'Ut diam erat, consequat quis posuere vel, dignissim id lectus. Aliquam sollicitudin ante ut diam convallis accumsan. Curabitur eu sapien vitae enim imperdiet tempus. Aliquam iaculis sodales consequat. Maecenas faucibus sem ut facilisis pulvinar. Mauris pulvinar diam eget eros suscipit convallis sed molestie erat. Ut tristique suscipit turpis, eu tempus turpis volutpat non. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nullam blandit, libero quis sagittis accumsan, ligula odio tristique ex, eleifend blandit urna justo sit amet ante. Mauris non vestibulum turpis.',
          'Nam congue molestie libero sed bibendum. Fusce posuere eros ac mauris dapibus pharetra eu convallis felis. Etiam pellentesque ultricies rhoncus. Duis fermentum orci sem, nec tincidunt nibh viverra eu. Aenean dictum accumsan dictum. Nulla sit amet ipsum quam. Cras eu neque maximus, vulputate diam eu, consequat enim. Aliquam erat volutpat. Phasellus et rutrum magna.',
          'Vestibulum blandit nunc non sollicitudin imperdiet. In ultrices mauris eu nisl molestie elementum. In vel erat enim. Duis non magna sem. Quisque et egestas neque, vitae fermentum ante. Maecenas venenatis nibh eu facilisis egestas. Praesent iaculis rhoncus sodales. Aenean feugiat lectus nec tortor bibendum lobortis. Quisque velit nulla, pellentesque ac accumsan sed, sollicitudin id urna. Fusce ut dui urna. Phasellus dictum eget arcu vel tempor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi gravida non dolor quis aliquam. In hac habitasse platea dictumst. Mauris convallis ex at massa finibus, ac feugiat diam vestibulum. Phasellus ultricies est orci, suscipit elementum arcu dictum at.',
          'Sed quis tortor urna. Cras et eleifend arcu, nec semper sapien. Nunc vel facilisis turpis. Donec ullamcorper augue at nunc sollicitudin, quis consectetur velit accumsan. Sed quis pretium felis. Pellentesque consequat est a tempor ullamcorper. Quisque ullamcorper nisl in nisl laoreet, id tincidunt ligula posuere. Nam in auctor nibh. Nunc lobortis posuere tellus, malesuada posuere mi tempus eget. Quisque ut magna non risus faucibus commodo. In hac habitasse platea dictumst. Cras ut massa nisi.',
        ],
        thumbnailUrl:
          'https://stablo-pro.web3templates.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fcijrdavx%2Fproduction%2Fb7d2fa6d0b250bd1e0c601645319db4cde42a01e-3958x5937.jpg%3Fw%3D2000%26auto%3Dformat&w=1200&q=75',
        thumbnailColor: '#2770cf',
        thumbnailAlt: 'Thumbnail',
      },
    ],
  },
  {
    name: 'Health & Wellness',
    slug: 'health-wellness',
    description:
      'Tips and information on physical and mental health, nutrition, and fitness.',
    color: 'green',
    subject: 'health',
    posts: [
      {
        title: `Lorem ipsum dolor sit amet, consectetur adipiscing elit`,
        slug: 'lorem-ipsum-dolor-sit-amet',
        paragraphs: [
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed nisi mauris. Sed finibus nec dui in scelerisque. Aliquam a convallis leo, id blandit nisi. Phasellus dui libero, cursus in dui a, congue sagittis metus. Integer posuere lacus ut efficitur porttitor. Duis in nulla eu risus eleifend egestas. Donec viverra ultrices aliquet. Suspendisse facilisis quis ante ac commodo. Donec bibendum sodales purus non vehicula. Quisque dignissim augue ut ipsum tincidunt, id efficitur nibh feugiat. Ut non nisi arcu.',
          'Ut diam erat, consequat quis posuere vel, dignissim id lectus. Aliquam sollicitudin ante ut diam convallis accumsan. Curabitur eu sapien vitae enim imperdiet tempus. Aliquam iaculis sodales consequat. Maecenas faucibus sem ut facilisis pulvinar. Mauris pulvinar diam eget eros suscipit convallis sed molestie erat. Ut tristique suscipit turpis, eu tempus turpis volutpat non. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nullam blandit, libero quis sagittis accumsan, ligula odio tristique ex, eleifend blandit urna justo sit amet ante. Mauris non vestibulum turpis.',
          'Nam congue molestie libero sed bibendum. Fusce posuere eros ac mauris dapibus pharetra eu convallis felis. Etiam pellentesque ultricies rhoncus. Duis fermentum orci sem, nec tincidunt nibh viverra eu. Aenean dictum accumsan dictum. Nulla sit amet ipsum quam. Cras eu neque maximus, vulputate diam eu, consequat enim. Aliquam erat volutpat. Phasellus et rutrum magna.',
          'Vestibulum blandit nunc non sollicitudin imperdiet. In ultrices mauris eu nisl molestie elementum. In vel erat enim. Duis non magna sem. Quisque et egestas neque, vitae fermentum ante. Maecenas venenatis nibh eu facilisis egestas. Praesent iaculis rhoncus sodales. Aenean feugiat lectus nec tortor bibendum lobortis. Quisque velit nulla, pellentesque ac accumsan sed, sollicitudin id urna. Fusce ut dui urna. Phasellus dictum eget arcu vel tempor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi gravida non dolor quis aliquam. In hac habitasse platea dictumst. Mauris convallis ex at massa finibus, ac feugiat diam vestibulum. Phasellus ultricies est orci, suscipit elementum arcu dictum at.',
          'Sed quis tortor urna. Cras et eleifend arcu, nec semper sapien. Nunc vel facilisis turpis. Donec ullamcorper augue at nunc sollicitudin, quis consectetur velit accumsan. Sed quis pretium felis. Pellentesque consequat est a tempor ullamcorper. Quisque ullamcorper nisl in nisl laoreet, id tincidunt ligula posuere. Nam in auctor nibh. Nunc lobortis posuere tellus, malesuada posuere mi tempus eget. Quisque ut magna non risus faucibus commodo. In hac habitasse platea dictumst. Cras ut massa nisi.',
        ],
        thumbnailUrl:
          'https://stablo-pro.web3templates.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fcijrdavx%2Fproduction%2F05951a0ec1a6ffc54f615ab160649e92fea982d0-800x764.png%3Frect%3D0%2C0%2C800%2C468%26w%3D800%26auto%3Dformat&w=1200&q=75',
        thumbnailColor: '#2770cf',
        thumbnailAlt: 'Thumbnail',
        featured: true,
      },
      {
        title: `Sed sit amet nulla lacus`,
        slug: 'sed-sit-amet-nulla-lacus',
        paragraphs: [
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed nisi mauris. Sed finibus nec dui in scelerisque. Aliquam a convallis leo, id blandit nisi. Phasellus dui libero, cursus in dui a, congue sagittis metus. Integer posuere lacus ut efficitur porttitor. Duis in nulla eu risus eleifend egestas. Donec viverra ultrices aliquet. Suspendisse facilisis quis ante ac commodo. Donec bibendum sodales purus non vehicula. Quisque dignissim augue ut ipsum tincidunt, id efficitur nibh feugiat. Ut non nisi arcu.',
          'Ut diam erat, consequat quis posuere vel, dignissim id lectus. Aliquam sollicitudin ante ut diam convallis accumsan. Curabitur eu sapien vitae enim imperdiet tempus. Aliquam iaculis sodales consequat. Maecenas faucibus sem ut facilisis pulvinar. Mauris pulvinar diam eget eros suscipit convallis sed molestie erat. Ut tristique suscipit turpis, eu tempus turpis volutpat non. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nullam blandit, libero quis sagittis accumsan, ligula odio tristique ex, eleifend blandit urna justo sit amet ante. Mauris non vestibulum turpis.',
          'Nam congue molestie libero sed bibendum. Fusce posuere eros ac mauris dapibus pharetra eu convallis felis. Etiam pellentesque ultricies rhoncus. Duis fermentum orci sem, nec tincidunt nibh viverra eu. Aenean dictum accumsan dictum. Nulla sit amet ipsum quam. Cras eu neque maximus, vulputate diam eu, consequat enim. Aliquam erat volutpat. Phasellus et rutrum magna.',
          'Vestibulum blandit nunc non sollicitudin imperdiet. In ultrices mauris eu nisl molestie elementum. In vel erat enim. Duis non magna sem. Quisque et egestas neque, vitae fermentum ante. Maecenas venenatis nibh eu facilisis egestas. Praesent iaculis rhoncus sodales. Aenean feugiat lectus nec tortor bibendum lobortis. Quisque velit nulla, pellentesque ac accumsan sed, sollicitudin id urna. Fusce ut dui urna. Phasellus dictum eget arcu vel tempor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi gravida non dolor quis aliquam. In hac habitasse platea dictumst. Mauris convallis ex at massa finibus, ac feugiat diam vestibulum. Phasellus ultricies est orci, suscipit elementum arcu dictum at.',
          'Sed quis tortor urna. Cras et eleifend arcu, nec semper sapien. Nunc vel facilisis turpis. Donec ullamcorper augue at nunc sollicitudin, quis consectetur velit accumsan. Sed quis pretium felis. Pellentesque consequat est a tempor ullamcorper. Quisque ullamcorper nisl in nisl laoreet, id tincidunt ligula posuere. Nam in auctor nibh. Nunc lobortis posuere tellus, malesuada posuere mi tempus eget. Quisque ut magna non risus faucibus commodo. In hac habitasse platea dictumst. Cras ut massa nisi.',
        ],
        thumbnailUrl:
          'https://stablo-pro.web3templates.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fcijrdavx%2Fproduction%2F2fda477a7e32f813abb9a8ef425939e6a91c7973-987x1481.png%3Frect%3D0%2C279%2C987%2C607%26w%3D987%26auto%3Dformat&w=1200&q=75',
        thumbnailColor: '#2770cf',
        thumbnailAlt: 'Thumbnail',
      },
      {
        title: `Fusce posuere eros ac mauris dapibus pharetra`,
        slug: 'fusce-posuere-eros-ac-mauris-dapibus-pharetra',
        paragraphs: [
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed nisi mauris. Sed finibus nec dui in scelerisque. Aliquam a convallis leo, id blandit nisi. Phasellus dui libero, cursus in dui a, congue sagittis metus. Integer posuere lacus ut efficitur porttitor. Duis in nulla eu risus eleifend egestas. Donec viverra ultrices aliquet. Suspendisse facilisis quis ante ac commodo. Donec bibendum sodales purus non vehicula. Quisque dignissim augue ut ipsum tincidunt, id efficitur nibh feugiat. Ut non nisi arcu.',
          'Ut diam erat, consequat quis posuere vel, dignissim id lectus. Aliquam sollicitudin ante ut diam convallis accumsan. Curabitur eu sapien vitae enim imperdiet tempus. Aliquam iaculis sodales consequat. Maecenas faucibus sem ut facilisis pulvinar. Mauris pulvinar diam eget eros suscipit convallis sed molestie erat. Ut tristique suscipit turpis, eu tempus turpis volutpat non. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nullam blandit, libero quis sagittis accumsan, ligula odio tristique ex, eleifend blandit urna justo sit amet ante. Mauris non vestibulum turpis.',
          'Nam congue molestie libero sed bibendum. Fusce posuere eros ac mauris dapibus pharetra eu convallis felis. Etiam pellentesque ultricies rhoncus. Duis fermentum orci sem, nec tincidunt nibh viverra eu. Aenean dictum accumsan dictum. Nulla sit amet ipsum quam. Cras eu neque maximus, vulputate diam eu, consequat enim. Aliquam erat volutpat. Phasellus et rutrum magna.',
          'Vestibulum blandit nunc non sollicitudin imperdiet. In ultrices mauris eu nisl molestie elementum. In vel erat enim. Duis non magna sem. Quisque et egestas neque, vitae fermentum ante. Maecenas venenatis nibh eu facilisis egestas. Praesent iaculis rhoncus sodales. Aenean feugiat lectus nec tortor bibendum lobortis. Quisque velit nulla, pellentesque ac accumsan sed, sollicitudin id urna. Fusce ut dui urna. Phasellus dictum eget arcu vel tempor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi gravida non dolor quis aliquam. In hac habitasse platea dictumst. Mauris convallis ex at massa finibus, ac feugiat diam vestibulum. Phasellus ultricies est orci, suscipit elementum arcu dictum at.',
          'Sed quis tortor urna. Cras et eleifend arcu, nec semper sapien. Nunc vel facilisis turpis. Donec ullamcorper augue at nunc sollicitudin, quis consectetur velit accumsan. Sed quis pretium felis. Pellentesque consequat est a tempor ullamcorper. Quisque ullamcorper nisl in nisl laoreet, id tincidunt ligula posuere. Nam in auctor nibh. Nunc lobortis posuere tellus, malesuada posuere mi tempus eget. Quisque ut magna non risus faucibus commodo. In hac habitasse platea dictumst. Cras ut massa nisi.',
        ],
        thumbnailUrl:
          'https://stablo-pro.web3templates.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fcijrdavx%2Fproduction%2Fa5bd8977e7fd82666c00a45948583b1f9912d392-3847x5583.jpg%3Fw%3D2000%26auto%3Dformat&w=1200&q=75',
        thumbnailColor: '#2770cf',
        thumbnailAlt: 'Thumbnail',
      },
      {
        title: `Nulla sit amet ipsum quam`,
        slug: 'nulla-sit-amet-ipsum-quam',
        paragraphs: [
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed nisi mauris. Sed finibus nec dui in scelerisque. Aliquam a convallis leo, id blandit nisi. Phasellus dui libero, cursus in dui a, congue sagittis metus. Integer posuere lacus ut efficitur porttitor. Duis in nulla eu risus eleifend egestas. Donec viverra ultrices aliquet. Suspendisse facilisis quis ante ac commodo. Donec bibendum sodales purus non vehicula. Quisque dignissim augue ut ipsum tincidunt, id efficitur nibh feugiat. Ut non nisi arcu.',
          'Ut diam erat, consequat quis posuere vel, dignissim id lectus. Aliquam sollicitudin ante ut diam convallis accumsan. Curabitur eu sapien vitae enim imperdiet tempus. Aliquam iaculis sodales consequat. Maecenas faucibus sem ut facilisis pulvinar. Mauris pulvinar diam eget eros suscipit convallis sed molestie erat. Ut tristique suscipit turpis, eu tempus turpis volutpat non. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nullam blandit, libero quis sagittis accumsan, ligula odio tristique ex, eleifend blandit urna justo sit amet ante. Mauris non vestibulum turpis.',
          'Nam congue molestie libero sed bibendum. Fusce posuere eros ac mauris dapibus pharetra eu convallis felis. Etiam pellentesque ultricies rhoncus. Duis fermentum orci sem, nec tincidunt nibh viverra eu. Aenean dictum accumsan dictum. Nulla sit amet ipsum quam. Cras eu neque maximus, vulputate diam eu, consequat enim. Aliquam erat volutpat. Phasellus et rutrum magna.',
          'Vestibulum blandit nunc non sollicitudin imperdiet. In ultrices mauris eu nisl molestie elementum. In vel erat enim. Duis non magna sem. Quisque et egestas neque, vitae fermentum ante. Maecenas venenatis nibh eu facilisis egestas. Praesent iaculis rhoncus sodales. Aenean feugiat lectus nec tortor bibendum lobortis. Quisque velit nulla, pellentesque ac accumsan sed, sollicitudin id urna. Fusce ut dui urna. Phasellus dictum eget arcu vel tempor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi gravida non dolor quis aliquam. In hac habitasse platea dictumst. Mauris convallis ex at massa finibus, ac feugiat diam vestibulum. Phasellus ultricies est orci, suscipit elementum arcu dictum at.',
          'Sed quis tortor urna. Cras et eleifend arcu, nec semper sapien. Nunc vel facilisis turpis. Donec ullamcorper augue at nunc sollicitudin, quis consectetur velit accumsan. Sed quis pretium felis. Pellentesque consequat est a tempor ullamcorper. Quisque ullamcorper nisl in nisl laoreet, id tincidunt ligula posuere. Nam in auctor nibh. Nunc lobortis posuere tellus, malesuada posuere mi tempus eget. Quisque ut magna non risus faucibus commodo. In hac habitasse platea dictumst. Cras ut massa nisi.',
        ],
        thumbnailUrl:
          'https://stablo-pro.web3templates.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fcijrdavx%2Fproduction%2F279c03681911845947cd044b4ac9e91d7a3a628c-987x1481.png%3Fw%3D987%26auto%3Dformat&w=1200&q=75',
        thumbnailColor: '#2770cf',
        thumbnailAlt: 'Thumbnail',
      },
      {
        title: `Cras eu neque maximus, vulputate diam eu, consequat enim`,
        slug: 'cras-eu-neque-maximus-vulputate-diam-eu-consequat-enim',
        paragraphs: [
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed nisi mauris. Sed finibus nec dui in scelerisque. Aliquam a convallis leo, id blandit nisi. Phasellus dui libero, cursus in dui a, congue sagittis metus. Integer posuere lacus ut efficitur porttitor. Duis in nulla eu risus eleifend egestas. Donec viverra ultrices aliquet. Suspendisse facilisis quis ante ac commodo. Donec bibendum sodales purus non vehicula. Quisque dignissim augue ut ipsum tincidunt, id efficitur nibh feugiat. Ut non nisi arcu.',
          'Ut diam erat, consequat quis posuere vel, dignissim id lectus. Aliquam sollicitudin ante ut diam convallis accumsan. Curabitur eu sapien vitae enim imperdiet tempus. Aliquam iaculis sodales consequat. Maecenas faucibus sem ut facilisis pulvinar. Mauris pulvinar diam eget eros suscipit convallis sed molestie erat. Ut tristique suscipit turpis, eu tempus turpis volutpat non. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nullam blandit, libero quis sagittis accumsan, ligula odio tristique ex, eleifend blandit urna justo sit amet ante. Mauris non vestibulum turpis.',
          'Nam congue molestie libero sed bibendum. Fusce posuere eros ac mauris dapibus pharetra eu convallis felis. Etiam pellentesque ultricies rhoncus. Duis fermentum orci sem, nec tincidunt nibh viverra eu. Aenean dictum accumsan dictum. Nulla sit amet ipsum quam. Cras eu neque maximus, vulputate diam eu, consequat enim. Aliquam erat volutpat. Phasellus et rutrum magna.',
          'Vestibulum blandit nunc non sollicitudin imperdiet. In ultrices mauris eu nisl molestie elementum. In vel erat enim. Duis non magna sem. Quisque et egestas neque, vitae fermentum ante. Maecenas venenatis nibh eu facilisis egestas. Praesent iaculis rhoncus sodales. Aenean feugiat lectus nec tortor bibendum lobortis. Quisque velit nulla, pellentesque ac accumsan sed, sollicitudin id urna. Fusce ut dui urna. Phasellus dictum eget arcu vel tempor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi gravida non dolor quis aliquam. In hac habitasse platea dictumst. Mauris convallis ex at massa finibus, ac feugiat diam vestibulum. Phasellus ultricies est orci, suscipit elementum arcu dictum at.',
          'Sed quis tortor urna. Cras et eleifend arcu, nec semper sapien. Nunc vel facilisis turpis. Donec ullamcorper augue at nunc sollicitudin, quis consectetur velit accumsan. Sed quis pretium felis. Pellentesque consequat est a tempor ullamcorper. Quisque ullamcorper nisl in nisl laoreet, id tincidunt ligula posuere. Nam in auctor nibh. Nunc lobortis posuere tellus, malesuada posuere mi tempus eget. Quisque ut magna non risus faucibus commodo. In hac habitasse platea dictumst. Cras ut massa nisi.',
        ],
        thumbnailUrl:
          'https://stablo-pro.web3templates.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fcijrdavx%2Fproduction%2F123fb8836aaf097b83d4c5e8fde544dd1747af0d-5064x3376.jpg%3Fw%3D2000%26auto%3Dformat&w=1200&q=75',
        thumbnailColor: '#2770cf',
        thumbnailAlt: 'Thumbnail',
      },
      {
        title: `Aliquam erat volutpat`,
        slug: 'aliquam-erat-volutpat',
        paragraphs: [
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed nisi mauris. Sed finibus nec dui in scelerisque. Aliquam a convallis leo, id blandit nisi. Phasellus dui libero, cursus in dui a, congue sagittis metus. Integer posuere lacus ut efficitur porttitor. Duis in nulla eu risus eleifend egestas. Donec viverra ultrices aliquet. Suspendisse facilisis quis ante ac commodo. Donec bibendum sodales purus non vehicula. Quisque dignissim augue ut ipsum tincidunt, id efficitur nibh feugiat. Ut non nisi arcu.',
          'Ut diam erat, consequat quis posuere vel, dignissim id lectus. Aliquam sollicitudin ante ut diam convallis accumsan. Curabitur eu sapien vitae enim imperdiet tempus. Aliquam iaculis sodales consequat. Maecenas faucibus sem ut facilisis pulvinar. Mauris pulvinar diam eget eros suscipit convallis sed molestie erat. Ut tristique suscipit turpis, eu tempus turpis volutpat non. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nullam blandit, libero quis sagittis accumsan, ligula odio tristique ex, eleifend blandit urna justo sit amet ante. Mauris non vestibulum turpis.',
          'Nam congue molestie libero sed bibendum. Fusce posuere eros ac mauris dapibus pharetra eu convallis felis. Etiam pellentesque ultricies rhoncus. Duis fermentum orci sem, nec tincidunt nibh viverra eu. Aenean dictum accumsan dictum. Nulla sit amet ipsum quam. Cras eu neque maximus, vulputate diam eu, consequat enim. Aliquam erat volutpat. Phasellus et rutrum magna.',
          'Vestibulum blandit nunc non sollicitudin imperdiet. In ultrices mauris eu nisl molestie elementum. In vel erat enim. Duis non magna sem. Quisque et egestas neque, vitae fermentum ante. Maecenas venenatis nibh eu facilisis egestas. Praesent iaculis rhoncus sodales. Aenean feugiat lectus nec tortor bibendum lobortis. Quisque velit nulla, pellentesque ac accumsan sed, sollicitudin id urna. Fusce ut dui urna. Phasellus dictum eget arcu vel tempor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi gravida non dolor quis aliquam. In hac habitasse platea dictumst. Mauris convallis ex at massa finibus, ac feugiat diam vestibulum. Phasellus ultricies est orci, suscipit elementum arcu dictum at.',
          'Sed quis tortor urna. Cras et eleifend arcu, nec semper sapien. Nunc vel facilisis turpis. Donec ullamcorper augue at nunc sollicitudin, quis consectetur velit accumsan. Sed quis pretium felis. Pellentesque consequat est a tempor ullamcorper. Quisque ullamcorper nisl in nisl laoreet, id tincidunt ligula posuere. Nam in auctor nibh. Nunc lobortis posuere tellus, malesuada posuere mi tempus eget. Quisque ut magna non risus faucibus commodo. In hac habitasse platea dictumst. Cras ut massa nisi.',
        ],
        thumbnailUrl:
          'https://stablo-pro.web3templates.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fcijrdavx%2Fproduction%2F5be1635417f1814b3fb09f8e9f74f37079899f72-4032x3024.jpg%3Fw%3D2000%26auto%3Dformat&w=1200&q=75',
        thumbnailColor: '#2770cf',
        thumbnailAlt: 'Thumbnail',
      },
      {
        title: `Phasellus et rutrum magna`,
        slug: 'phasellus-et-rutrum-magna',
        paragraphs: [
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed nisi mauris. Sed finibus nec dui in scelerisque. Aliquam a convallis leo, id blandit nisi. Phasellus dui libero, cursus in dui a, congue sagittis metus. Integer posuere lacus ut efficitur porttitor. Duis in nulla eu risus eleifend egestas. Donec viverra ultrices aliquet. Suspendisse facilisis quis ante ac commodo. Donec bibendum sodales purus non vehicula. Quisque dignissim augue ut ipsum tincidunt, id efficitur nibh feugiat. Ut non nisi arcu.',
          'Ut diam erat, consequat quis posuere vel, dignissim id lectus. Aliquam sollicitudin ante ut diam convallis accumsan. Curabitur eu sapien vitae enim imperdiet tempus. Aliquam iaculis sodales consequat. Maecenas faucibus sem ut facilisis pulvinar. Mauris pulvinar diam eget eros suscipit convallis sed molestie erat. Ut tristique suscipit turpis, eu tempus turpis volutpat non. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nullam blandit, libero quis sagittis accumsan, ligula odio tristique ex, eleifend blandit urna justo sit amet ante. Mauris non vestibulum turpis.',
          'Nam congue molestie libero sed bibendum. Fusce posuere eros ac mauris dapibus pharetra eu convallis felis. Etiam pellentesque ultricies rhoncus. Duis fermentum orci sem, nec tincidunt nibh viverra eu. Aenean dictum accumsan dictum. Nulla sit amet ipsum quam. Cras eu neque maximus, vulputate diam eu, consequat enim. Aliquam erat volutpat. Phasellus et rutrum magna.',
          'Vestibulum blandit nunc non sollicitudin imperdiet. In ultrices mauris eu nisl molestie elementum. In vel erat enim. Duis non magna sem. Quisque et egestas neque, vitae fermentum ante. Maecenas venenatis nibh eu facilisis egestas. Praesent iaculis rhoncus sodales. Aenean feugiat lectus nec tortor bibendum lobortis. Quisque velit nulla, pellentesque ac accumsan sed, sollicitudin id urna. Fusce ut dui urna. Phasellus dictum eget arcu vel tempor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi gravida non dolor quis aliquam. In hac habitasse platea dictumst. Mauris convallis ex at massa finibus, ac feugiat diam vestibulum. Phasellus ultricies est orci, suscipit elementum arcu dictum at.',
          'Sed quis tortor urna. Cras et eleifend arcu, nec semper sapien. Nunc vel facilisis turpis. Donec ullamcorper augue at nunc sollicitudin, quis consectetur velit accumsan. Sed quis pretium felis. Pellentesque consequat est a tempor ullamcorper. Quisque ullamcorper nisl in nisl laoreet, id tincidunt ligula posuere. Nam in auctor nibh. Nunc lobortis posuere tellus, malesuada posuere mi tempus eget. Quisque ut magna non risus faucibus commodo. In hac habitasse platea dictumst. Cras ut massa nisi.',
        ],
        thumbnailUrl:
          'https://stablo-pro.web3templates.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fcijrdavx%2Fproduction%2Fd4898616e2973584b1b8a0bfdb5c6cf7c36ab012-1064x1330.png%3Fw%3D1064%26auto%3Dformat&w=1200&q=75',
        thumbnailColor: '#2770cf',
        thumbnailAlt: 'Thumbnail',
      },
      {
        title: `Vestibulum blandit nunc non sollicitudin imperdiet`,
        slug: 'vestibulum-blandit-nunc-non-sollicitudin-imperdiet',
        paragraphs: [
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed nisi mauris. Sed finibus nec dui in scelerisque. Aliquam a convallis leo, id blandit nisi. Phasellus dui libero, cursus in dui a, congue sagittis metus. Integer posuere lacus ut efficitur porttitor. Duis in nulla eu risus eleifend egestas. Donec viverra ultrices aliquet. Suspendisse facilisis quis ante ac commodo. Donec bibendum sodales purus non vehicula. Quisque dignissim augue ut ipsum tincidunt, id efficitur nibh feugiat. Ut non nisi arcu.',
          'Ut diam erat, consequat quis posuere vel, dignissim id lectus. Aliquam sollicitudin ante ut diam convallis accumsan. Curabitur eu sapien vitae enim imperdiet tempus. Aliquam iaculis sodales consequat. Maecenas faucibus sem ut facilisis pulvinar. Mauris pulvinar diam eget eros suscipit convallis sed molestie erat. Ut tristique suscipit turpis, eu tempus turpis volutpat non. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nullam blandit, libero quis sagittis accumsan, ligula odio tristique ex, eleifend blandit urna justo sit amet ante. Mauris non vestibulum turpis.',
          'Nam congue molestie libero sed bibendum. Fusce posuere eros ac mauris dapibus pharetra eu convallis felis. Etiam pellentesque ultricies rhoncus. Duis fermentum orci sem, nec tincidunt nibh viverra eu. Aenean dictum accumsan dictum. Nulla sit amet ipsum quam. Cras eu neque maximus, vulputate diam eu, consequat enim. Aliquam erat volutpat. Phasellus et rutrum magna.',
          'Vestibulum blandit nunc non sollicitudin imperdiet. In ultrices mauris eu nisl molestie elementum. In vel erat enim. Duis non magna sem. Quisque et egestas neque, vitae fermentum ante. Maecenas venenatis nibh eu facilisis egestas. Praesent iaculis rhoncus sodales. Aenean feugiat lectus nec tortor bibendum lobortis. Quisque velit nulla, pellentesque ac accumsan sed, sollicitudin id urna. Fusce ut dui urna. Phasellus dictum eget arcu vel tempor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi gravida non dolor quis aliquam. In hac habitasse platea dictumst. Mauris convallis ex at massa finibus, ac feugiat diam vestibulum. Phasellus ultricies est orci, suscipit elementum arcu dictum at.',
          'Sed quis tortor urna. Cras et eleifend arcu, nec semper sapien. Nunc vel facilisis turpis. Donec ullamcorper augue at nunc sollicitudin, quis consectetur velit accumsan. Sed quis pretium felis. Pellentesque consequat est a tempor ullamcorper. Quisque ullamcorper nisl in nisl laoreet, id tincidunt ligula posuere. Nam in auctor nibh. Nunc lobortis posuere tellus, malesuada posuere mi tempus eget. Quisque ut magna non risus faucibus commodo. In hac habitasse platea dictumst. Cras ut massa nisi.',
        ],
        thumbnailUrl:
          'https://stablo-pro.web3templates.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fcijrdavx%2Fproduction%2F86cb3a2f0e43610371a7184483c5567bd9e6e94f-2400x1600.jpg%3Fw%3D2000%26auto%3Dformat&w=1200&q=75',
        thumbnailColor: '#2770cf',
        thumbnailAlt: 'Thumbnail',
      },
    ],
  },
  {
    name: 'Travel',
    slug: 'travel',
    description:
      'Travel stories, guides, and tips for destinations around the world.',
    color: 'orange',
    subject: 'travel',
    posts: [
      {
        title: `Lorem ipsum dolor sit amet, consectetur adipiscing elit`,
        slug: 'lorem-ipsum-dolor-sit-amet',
        paragraphs: [
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed nisi mauris. Sed finibus nec dui in scelerisque. Aliquam a convallis leo, id blandit nisi. Phasellus dui libero, cursus in dui a, congue sagittis metus. Integer posuere lacus ut efficitur porttitor. Duis in nulla eu risus eleifend egestas. Donec viverra ultrices aliquet. Suspendisse facilisis quis ante ac commodo. Donec bibendum sodales purus non vehicula. Quisque dignissim augue ut ipsum tincidunt, id efficitur nibh feugiat. Ut non nisi arcu.',
          'Ut diam erat, consequat quis posuere vel, dignissim id lectus. Aliquam sollicitudin ante ut diam convallis accumsan. Curabitur eu sapien vitae enim imperdiet tempus. Aliquam iaculis sodales consequat. Maecenas faucibus sem ut facilisis pulvinar. Mauris pulvinar diam eget eros suscipit convallis sed molestie erat. Ut tristique suscipit turpis, eu tempus turpis volutpat non. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nullam blandit, libero quis sagittis accumsan, ligula odio tristique ex, eleifend blandit urna justo sit amet ante. Mauris non vestibulum turpis.',
          'Nam congue molestie libero sed bibendum. Fusce posuere eros ac mauris dapibus pharetra eu convallis felis. Etiam pellentesque ultricies rhoncus. Duis fermentum orci sem, nec tincidunt nibh viverra eu. Aenean dictum accumsan dictum. Nulla sit amet ipsum quam. Cras eu neque maximus, vulputate diam eu, consequat enim. Aliquam erat volutpat. Phasellus et rutrum magna.',
          'Vestibulum blandit nunc non sollicitudin imperdiet. In ultrices mauris eu nisl molestie elementum. In vel erat enim. Duis non magna sem. Quisque et egestas neque, vitae fermentum ante. Maecenas venenatis nibh eu facilisis egestas. Praesent iaculis rhoncus sodales. Aenean feugiat lectus nec tortor bibendum lobortis. Quisque velit nulla, pellentesque ac accumsan sed, sollicitudin id urna. Fusce ut dui urna. Phasellus dictum eget arcu vel tempor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi gravida non dolor quis aliquam. In hac habitasse platea dictumst. Mauris convallis ex at massa finibus, ac feugiat diam vestibulum. Phasellus ultricies est orci, suscipit elementum arcu dictum at.',
          'Sed quis tortor urna. Cras et eleifend arcu, nec semper sapien. Nunc vel facilisis turpis. Donec ullamcorper augue at nunc sollicitudin, quis consectetur velit accumsan. Sed quis pretium felis. Pellentesque consequat est a tempor ullamcorper. Quisque ullamcorper nisl in nisl laoreet, id tincidunt ligula posuere. Nam in auctor nibh. Nunc lobortis posuere tellus, malesuada posuere mi tempus eget. Quisque ut magna non risus faucibus commodo. In hac habitasse platea dictumst. Cras ut massa nisi.',
        ],
        thumbnailUrl:
          'https://stablo-pro.web3templates.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fcijrdavx%2Fproduction%2F05951a0ec1a6ffc54f615ab160649e92fea982d0-800x764.png%3Frect%3D0%2C0%2C800%2C468%26w%3D800%26auto%3Dformat&w=1200&q=75',
        thumbnailColor: '#2770cf',
        thumbnailAlt: 'Thumbnail',
        featured: true,
      },
      {
        title: `Sed sit amet nulla lacus`,
        slug: 'sed-sit-amet-nulla-lacus',
        paragraphs: [
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed nisi mauris. Sed finibus nec dui in scelerisque. Aliquam a convallis leo, id blandit nisi. Phasellus dui libero, cursus in dui a, congue sagittis metus. Integer posuere lacus ut efficitur porttitor. Duis in nulla eu risus eleifend egestas. Donec viverra ultrices aliquet. Suspendisse facilisis quis ante ac commodo. Donec bibendum sodales purus non vehicula. Quisque dignissim augue ut ipsum tincidunt, id efficitur nibh feugiat. Ut non nisi arcu.',
          'Ut diam erat, consequat quis posuere vel, dignissim id lectus. Aliquam sollicitudin ante ut diam convallis accumsan. Curabitur eu sapien vitae enim imperdiet tempus. Aliquam iaculis sodales consequat. Maecenas faucibus sem ut facilisis pulvinar. Mauris pulvinar diam eget eros suscipit convallis sed molestie erat. Ut tristique suscipit turpis, eu tempus turpis volutpat non. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nullam blandit, libero quis sagittis accumsan, ligula odio tristique ex, eleifend blandit urna justo sit amet ante. Mauris non vestibulum turpis.',
          'Nam congue molestie libero sed bibendum. Fusce posuere eros ac mauris dapibus pharetra eu convallis felis. Etiam pellentesque ultricies rhoncus. Duis fermentum orci sem, nec tincidunt nibh viverra eu. Aenean dictum accumsan dictum. Nulla sit amet ipsum quam. Cras eu neque maximus, vulputate diam eu, consequat enim. Aliquam erat volutpat. Phasellus et rutrum magna.',
          'Vestibulum blandit nunc non sollicitudin imperdiet. In ultrices mauris eu nisl molestie elementum. In vel erat enim. Duis non magna sem. Quisque et egestas neque, vitae fermentum ante. Maecenas venenatis nibh eu facilisis egestas. Praesent iaculis rhoncus sodales. Aenean feugiat lectus nec tortor bibendum lobortis. Quisque velit nulla, pellentesque ac accumsan sed, sollicitudin id urna. Fusce ut dui urna. Phasellus dictum eget arcu vel tempor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi gravida non dolor quis aliquam. In hac habitasse platea dictumst. Mauris convallis ex at massa finibus, ac feugiat diam vestibulum. Phasellus ultricies est orci, suscipit elementum arcu dictum at.',
          'Sed quis tortor urna. Cras et eleifend arcu, nec semper sapien. Nunc vel facilisis turpis. Donec ullamcorper augue at nunc sollicitudin, quis consectetur velit accumsan. Sed quis pretium felis. Pellentesque consequat est a tempor ullamcorper. Quisque ullamcorper nisl in nisl laoreet, id tincidunt ligula posuere. Nam in auctor nibh. Nunc lobortis posuere tellus, malesuada posuere mi tempus eget. Quisque ut magna non risus faucibus commodo. In hac habitasse platea dictumst. Cras ut massa nisi.',
        ],
        thumbnailUrl:
          'https://stablo-pro.web3templates.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fcijrdavx%2Fproduction%2F2fda477a7e32f813abb9a8ef425939e6a91c7973-987x1481.png%3Frect%3D0%2C279%2C987%2C607%26w%3D987%26auto%3Dformat&w=1200&q=75',
        thumbnailColor: '#2770cf',
        thumbnailAlt: 'Thumbnail',
      },
      {
        title: `Fusce posuere eros ac mauris dapibus pharetra`,
        slug: 'fusce-posuere-eros-ac-mauris-dapibus-pharetra',
        paragraphs: [
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed nisi mauris. Sed finibus nec dui in scelerisque. Aliquam a convallis leo, id blandit nisi. Phasellus dui libero, cursus in dui a, congue sagittis metus. Integer posuere lacus ut efficitur porttitor. Duis in nulla eu risus eleifend egestas. Donec viverra ultrices aliquet. Suspendisse facilisis quis ante ac commodo. Donec bibendum sodales purus non vehicula. Quisque dignissim augue ut ipsum tincidunt, id efficitur nibh feugiat. Ut non nisi arcu.',
          'Ut diam erat, consequat quis posuere vel, dignissim id lectus. Aliquam sollicitudin ante ut diam convallis accumsan. Curabitur eu sapien vitae enim imperdiet tempus. Aliquam iaculis sodales consequat. Maecenas faucibus sem ut facilisis pulvinar. Mauris pulvinar diam eget eros suscipit convallis sed molestie erat. Ut tristique suscipit turpis, eu tempus turpis volutpat non. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nullam blandit, libero quis sagittis accumsan, ligula odio tristique ex, eleifend blandit urna justo sit amet ante. Mauris non vestibulum turpis.',
          'Nam congue molestie libero sed bibendum. Fusce posuere eros ac mauris dapibus pharetra eu convallis felis. Etiam pellentesque ultricies rhoncus. Duis fermentum orci sem, nec tincidunt nibh viverra eu. Aenean dictum accumsan dictum. Nulla sit amet ipsum quam. Cras eu neque maximus, vulputate diam eu, consequat enim. Aliquam erat volutpat. Phasellus et rutrum magna.',
          'Vestibulum blandit nunc non sollicitudin imperdiet. In ultrices mauris eu nisl molestie elementum. In vel erat enim. Duis non magna sem. Quisque et egestas neque, vitae fermentum ante. Maecenas venenatis nibh eu facilisis egestas. Praesent iaculis rhoncus sodales. Aenean feugiat lectus nec tortor bibendum lobortis. Quisque velit nulla, pellentesque ac accumsan sed, sollicitudin id urna. Fusce ut dui urna. Phasellus dictum eget arcu vel tempor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi gravida non dolor quis aliquam. In hac habitasse platea dictumst. Mauris convallis ex at massa finibus, ac feugiat diam vestibulum. Phasellus ultricies est orci, suscipit elementum arcu dictum at.',
          'Sed quis tortor urna. Cras et eleifend arcu, nec semper sapien. Nunc vel facilisis turpis. Donec ullamcorper augue at nunc sollicitudin, quis consectetur velit accumsan. Sed quis pretium felis. Pellentesque consequat est a tempor ullamcorper. Quisque ullamcorper nisl in nisl laoreet, id tincidunt ligula posuere. Nam in auctor nibh. Nunc lobortis posuere tellus, malesuada posuere mi tempus eget. Quisque ut magna non risus faucibus commodo. In hac habitasse platea dictumst. Cras ut massa nisi.',
        ],
        thumbnailUrl:
          'https://stablo-pro.web3templates.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fcijrdavx%2Fproduction%2Fa5bd8977e7fd82666c00a45948583b1f9912d392-3847x5583.jpg%3Fw%3D2000%26auto%3Dformat&w=1200&q=75',
        thumbnailColor: '#2770cf',
        thumbnailAlt: 'Thumbnail',
      },
      {
        title: `Nulla sit amet ipsum quam`,
        slug: 'nulla-sit-amet-ipsum-quam',
        paragraphs: [
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed nisi mauris. Sed finibus nec dui in scelerisque. Aliquam a convallis leo, id blandit nisi. Phasellus dui libero, cursus in dui a, congue sagittis metus. Integer posuere lacus ut efficitur porttitor. Duis in nulla eu risus eleifend egestas. Donec viverra ultrices aliquet. Suspendisse facilisis quis ante ac commodo. Donec bibendum sodales purus non vehicula. Quisque dignissim augue ut ipsum tincidunt, id efficitur nibh feugiat. Ut non nisi arcu.',
          'Ut diam erat, consequat quis posuere vel, dignissim id lectus. Aliquam sollicitudin ante ut diam convallis accumsan. Curabitur eu sapien vitae enim imperdiet tempus. Aliquam iaculis sodales consequat. Maecenas faucibus sem ut facilisis pulvinar. Mauris pulvinar diam eget eros suscipit convallis sed molestie erat. Ut tristique suscipit turpis, eu tempus turpis volutpat non. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nullam blandit, libero quis sagittis accumsan, ligula odio tristique ex, eleifend blandit urna justo sit amet ante. Mauris non vestibulum turpis.',
          'Nam congue molestie libero sed bibendum. Fusce posuere eros ac mauris dapibus pharetra eu convallis felis. Etiam pellentesque ultricies rhoncus. Duis fermentum orci sem, nec tincidunt nibh viverra eu. Aenean dictum accumsan dictum. Nulla sit amet ipsum quam. Cras eu neque maximus, vulputate diam eu, consequat enim. Aliquam erat volutpat. Phasellus et rutrum magna.',
          'Vestibulum blandit nunc non sollicitudin imperdiet. In ultrices mauris eu nisl molestie elementum. In vel erat enim. Duis non magna sem. Quisque et egestas neque, vitae fermentum ante. Maecenas venenatis nibh eu facilisis egestas. Praesent iaculis rhoncus sodales. Aenean feugiat lectus nec tortor bibendum lobortis. Quisque velit nulla, pellentesque ac accumsan sed, sollicitudin id urna. Fusce ut dui urna. Phasellus dictum eget arcu vel tempor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi gravida non dolor quis aliquam. In hac habitasse platea dictumst. Mauris convallis ex at massa finibus, ac feugiat diam vestibulum. Phasellus ultricies est orci, suscipit elementum arcu dictum at.',
          'Sed quis tortor urna. Cras et eleifend arcu, nec semper sapien. Nunc vel facilisis turpis. Donec ullamcorper augue at nunc sollicitudin, quis consectetur velit accumsan. Sed quis pretium felis. Pellentesque consequat est a tempor ullamcorper. Quisque ullamcorper nisl in nisl laoreet, id tincidunt ligula posuere. Nam in auctor nibh. Nunc lobortis posuere tellus, malesuada posuere mi tempus eget. Quisque ut magna non risus faucibus commodo. In hac habitasse platea dictumst. Cras ut massa nisi.',
        ],
        thumbnailUrl:
          'https://stablo-pro.web3templates.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fcijrdavx%2Fproduction%2F279c03681911845947cd044b4ac9e91d7a3a628c-987x1481.png%3Fw%3D987%26auto%3Dformat&w=1200&q=75',
        thumbnailColor: '#2770cf',
        thumbnailAlt: 'Thumbnail',
      },
      {
        title: `Cras eu neque maximus, vulputate diam eu, consequat enim`,
        slug: 'cras-eu-neque-maximus-vulputate-diam-eu-consequat-enim',
        paragraphs: [
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed nisi mauris. Sed finibus nec dui in scelerisque. Aliquam a convallis leo, id blandit nisi. Phasellus dui libero, cursus in dui a, congue sagittis metus. Integer posuere lacus ut efficitur porttitor. Duis in nulla eu risus eleifend egestas. Donec viverra ultrices aliquet. Suspendisse facilisis quis ante ac commodo. Donec bibendum sodales purus non vehicula. Quisque dignissim augue ut ipsum tincidunt, id efficitur nibh feugiat. Ut non nisi arcu.',
          'Ut diam erat, consequat quis posuere vel, dignissim id lectus. Aliquam sollicitudin ante ut diam convallis accumsan. Curabitur eu sapien vitae enim imperdiet tempus. Aliquam iaculis sodales consequat. Maecenas faucibus sem ut facilisis pulvinar. Mauris pulvinar diam eget eros suscipit convallis sed molestie erat. Ut tristique suscipit turpis, eu tempus turpis volutpat non. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nullam blandit, libero quis sagittis accumsan, ligula odio tristique ex, eleifend blandit urna justo sit amet ante. Mauris non vestibulum turpis.',
          'Nam congue molestie libero sed bibendum. Fusce posuere eros ac mauris dapibus pharetra eu convallis felis. Etiam pellentesque ultricies rhoncus. Duis fermentum orci sem, nec tincidunt nibh viverra eu. Aenean dictum accumsan dictum. Nulla sit amet ipsum quam. Cras eu neque maximus, vulputate diam eu, consequat enim. Aliquam erat volutpat. Phasellus et rutrum magna.',
          'Vestibulum blandit nunc non sollicitudin imperdiet. In ultrices mauris eu nisl molestie elementum. In vel erat enim. Duis non magna sem. Quisque et egestas neque, vitae fermentum ante. Maecenas venenatis nibh eu facilisis egestas. Praesent iaculis rhoncus sodales. Aenean feugiat lectus nec tortor bibendum lobortis. Quisque velit nulla, pellentesque ac accumsan sed, sollicitudin id urna. Fusce ut dui urna. Phasellus dictum eget arcu vel tempor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi gravida non dolor quis aliquam. In hac habitasse platea dictumst. Mauris convallis ex at massa finibus, ac feugiat diam vestibulum. Phasellus ultricies est orci, suscipit elementum arcu dictum at.',
          'Sed quis tortor urna. Cras et eleifend arcu, nec semper sapien. Nunc vel facilisis turpis. Donec ullamcorper augue at nunc sollicitudin, quis consectetur velit accumsan. Sed quis pretium felis. Pellentesque consequat est a tempor ullamcorper. Quisque ullamcorper nisl in nisl laoreet, id tincidunt ligula posuere. Nam in auctor nibh. Nunc lobortis posuere tellus, malesuada posuere mi tempus eget. Quisque ut magna non risus faucibus commodo. In hac habitasse platea dictumst. Cras ut massa nisi.',
        ],
        thumbnailUrl:
          'https://stablo-pro.web3templates.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fcijrdavx%2Fproduction%2F123fb8836aaf097b83d4c5e8fde544dd1747af0d-5064x3376.jpg%3Fw%3D2000%26auto%3Dformat&w=1200&q=75',
        thumbnailColor: '#2770cf',
        thumbnailAlt: 'Thumbnail',
      },
      {
        title: `Aliquam erat volutpat`,
        slug: 'aliquam-erat-volutpat',
        paragraphs: [
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed nisi mauris. Sed finibus nec dui in scelerisque. Aliquam a convallis leo, id blandit nisi. Phasellus dui libero, cursus in dui a, congue sagittis metus. Integer posuere lacus ut efficitur porttitor. Duis in nulla eu risus eleifend egestas. Donec viverra ultrices aliquet. Suspendisse facilisis quis ante ac commodo. Donec bibendum sodales purus non vehicula. Quisque dignissim augue ut ipsum tincidunt, id efficitur nibh feugiat. Ut non nisi arcu.',
          'Ut diam erat, consequat quis posuere vel, dignissim id lectus. Aliquam sollicitudin ante ut diam convallis accumsan. Curabitur eu sapien vitae enim imperdiet tempus. Aliquam iaculis sodales consequat. Maecenas faucibus sem ut facilisis pulvinar. Mauris pulvinar diam eget eros suscipit convallis sed molestie erat. Ut tristique suscipit turpis, eu tempus turpis volutpat non. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nullam blandit, libero quis sagittis accumsan, ligula odio tristique ex, eleifend blandit urna justo sit amet ante. Mauris non vestibulum turpis.',
          'Nam congue molestie libero sed bibendum. Fusce posuere eros ac mauris dapibus pharetra eu convallis felis. Etiam pellentesque ultricies rhoncus. Duis fermentum orci sem, nec tincidunt nibh viverra eu. Aenean dictum accumsan dictum. Nulla sit amet ipsum quam. Cras eu neque maximus, vulputate diam eu, consequat enim. Aliquam erat volutpat. Phasellus et rutrum magna.',
          'Vestibulum blandit nunc non sollicitudin imperdiet. In ultrices mauris eu nisl molestie elementum. In vel erat enim. Duis non magna sem. Quisque et egestas neque, vitae fermentum ante. Maecenas venenatis nibh eu facilisis egestas. Praesent iaculis rhoncus sodales. Aenean feugiat lectus nec tortor bibendum lobortis. Quisque velit nulla, pellentesque ac accumsan sed, sollicitudin id urna. Fusce ut dui urna. Phasellus dictum eget arcu vel tempor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi gravida non dolor quis aliquam. In hac habitasse platea dictumst. Mauris convallis ex at massa finibus, ac feugiat diam vestibulum. Phasellus ultricies est orci, suscipit elementum arcu dictum at.',
          'Sed quis tortor urna. Cras et eleifend arcu, nec semper sapien. Nunc vel facilisis turpis. Donec ullamcorper augue at nunc sollicitudin, quis consectetur velit accumsan. Sed quis pretium felis. Pellentesque consequat est a tempor ullamcorper. Quisque ullamcorper nisl in nisl laoreet, id tincidunt ligula posuere. Nam in auctor nibh. Nunc lobortis posuere tellus, malesuada posuere mi tempus eget. Quisque ut magna non risus faucibus commodo. In hac habitasse platea dictumst. Cras ut massa nisi.',
        ],
        thumbnailUrl:
          'https://stablo-pro.web3templates.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fcijrdavx%2Fproduction%2F5be1635417f1814b3fb09f8e9f74f37079899f72-4032x3024.jpg%3Fw%3D2000%26auto%3Dformat&w=1200&q=75',
        thumbnailColor: '#2770cf',
        thumbnailAlt: 'Thumbnail',
      },
      {
        title: `Phasellus et rutrum magna`,
        slug: 'phasellus-et-rutrum-magna',
        paragraphs: [
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed nisi mauris. Sed finibus nec dui in scelerisque. Aliquam a convallis leo, id blandit nisi. Phasellus dui libero, cursus in dui a, congue sagittis metus. Integer posuere lacus ut efficitur porttitor. Duis in nulla eu risus eleifend egestas. Donec viverra ultrices aliquet. Suspendisse facilisis quis ante ac commodo. Donec bibendum sodales purus non vehicula. Quisque dignissim augue ut ipsum tincidunt, id efficitur nibh feugiat. Ut non nisi arcu.',
          'Ut diam erat, consequat quis posuere vel, dignissim id lectus. Aliquam sollicitudin ante ut diam convallis accumsan. Curabitur eu sapien vitae enim imperdiet tempus. Aliquam iaculis sodales consequat. Maecenas faucibus sem ut facilisis pulvinar. Mauris pulvinar diam eget eros suscipit convallis sed molestie erat. Ut tristique suscipit turpis, eu tempus turpis volutpat non. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nullam blandit, libero quis sagittis accumsan, ligula odio tristique ex, eleifend blandit urna justo sit amet ante. Mauris non vestibulum turpis.',
          'Nam congue molestie libero sed bibendum. Fusce posuere eros ac mauris dapibus pharetra eu convallis felis. Etiam pellentesque ultricies rhoncus. Duis fermentum orci sem, nec tincidunt nibh viverra eu. Aenean dictum accumsan dictum. Nulla sit amet ipsum quam. Cras eu neque maximus, vulputate diam eu, consequat enim. Aliquam erat volutpat. Phasellus et rutrum magna.',
          'Vestibulum blandit nunc non sollicitudin imperdiet. In ultrices mauris eu nisl molestie elementum. In vel erat enim. Duis non magna sem. Quisque et egestas neque, vitae fermentum ante. Maecenas venenatis nibh eu facilisis egestas. Praesent iaculis rhoncus sodales. Aenean feugiat lectus nec tortor bibendum lobortis. Quisque velit nulla, pellentesque ac accumsan sed, sollicitudin id urna. Fusce ut dui urna. Phasellus dictum eget arcu vel tempor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi gravida non dolor quis aliquam. In hac habitasse platea dictumst. Mauris convallis ex at massa finibus, ac feugiat diam vestibulum. Phasellus ultricies est orci, suscipit elementum arcu dictum at.',
          'Sed quis tortor urna. Cras et eleifend arcu, nec semper sapien. Nunc vel facilisis turpis. Donec ullamcorper augue at nunc sollicitudin, quis consectetur velit accumsan. Sed quis pretium felis. Pellentesque consequat est a tempor ullamcorper. Quisque ullamcorper nisl in nisl laoreet, id tincidunt ligula posuere. Nam in auctor nibh. Nunc lobortis posuere tellus, malesuada posuere mi tempus eget. Quisque ut magna non risus faucibus commodo. In hac habitasse platea dictumst. Cras ut massa nisi.',
        ],
        thumbnailUrl:
          'https://stablo-pro.web3templates.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fcijrdavx%2Fproduction%2Fd4898616e2973584b1b8a0bfdb5c6cf7c36ab012-1064x1330.png%3Fw%3D1064%26auto%3Dformat&w=1200&q=75',
        thumbnailColor: '#2770cf',
        thumbnailAlt: 'Thumbnail',
      },
      {
        title: `Vestibulum blandit nunc non sollicitudin imperdiet`,
        slug: 'vestibulum-blandit-nunc-non-sollicitudin-imperdiet',
        paragraphs: [
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed nisi mauris. Sed finibus nec dui in scelerisque. Aliquam a convallis leo, id blandit nisi. Phasellus dui libero, cursus in dui a, congue sagittis metus. Integer posuere lacus ut efficitur porttitor. Duis in nulla eu risus eleifend egestas. Donec viverra ultrices aliquet. Suspendisse facilisis quis ante ac commodo. Donec bibendum sodales purus non vehicula. Quisque dignissim augue ut ipsum tincidunt, id efficitur nibh feugiat. Ut non nisi arcu.',
          'Ut diam erat, consequat quis posuere vel, dignissim id lectus. Aliquam sollicitudin ante ut diam convallis accumsan. Curabitur eu sapien vitae enim imperdiet tempus. Aliquam iaculis sodales consequat. Maecenas faucibus sem ut facilisis pulvinar. Mauris pulvinar diam eget eros suscipit convallis sed molestie erat. Ut tristique suscipit turpis, eu tempus turpis volutpat non. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nullam blandit, libero quis sagittis accumsan, ligula odio tristique ex, eleifend blandit urna justo sit amet ante. Mauris non vestibulum turpis.',
          'Nam congue molestie libero sed bibendum. Fusce posuere eros ac mauris dapibus pharetra eu convallis felis. Etiam pellentesque ultricies rhoncus. Duis fermentum orci sem, nec tincidunt nibh viverra eu. Aenean dictum accumsan dictum. Nulla sit amet ipsum quam. Cras eu neque maximus, vulputate diam eu, consequat enim. Aliquam erat volutpat. Phasellus et rutrum magna.',
          'Vestibulum blandit nunc non sollicitudin imperdiet. In ultrices mauris eu nisl molestie elementum. In vel erat enim. Duis non magna sem. Quisque et egestas neque, vitae fermentum ante. Maecenas venenatis nibh eu facilisis egestas. Praesent iaculis rhoncus sodales. Aenean feugiat lectus nec tortor bibendum lobortis. Quisque velit nulla, pellentesque ac accumsan sed, sollicitudin id urna. Fusce ut dui urna. Phasellus dictum eget arcu vel tempor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi gravida non dolor quis aliquam. In hac habitasse platea dictumst. Mauris convallis ex at massa finibus, ac feugiat diam vestibulum. Phasellus ultricies est orci, suscipit elementum arcu dictum at.',
          'Sed quis tortor urna. Cras et eleifend arcu, nec semper sapien. Nunc vel facilisis turpis. Donec ullamcorper augue at nunc sollicitudin, quis consectetur velit accumsan. Sed quis pretium felis. Pellentesque consequat est a tempor ullamcorper. Quisque ullamcorper nisl in nisl laoreet, id tincidunt ligula posuere. Nam in auctor nibh. Nunc lobortis posuere tellus, malesuada posuere mi tempus eget. Quisque ut magna non risus faucibus commodo. In hac habitasse platea dictumst. Cras ut massa nisi.',
        ],
        thumbnailUrl:
          'https://stablo-pro.web3templates.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fcijrdavx%2Fproduction%2F86cb3a2f0e43610371a7184483c5567bd9e6e94f-2400x1600.jpg%3Fw%3D2000%26auto%3Dformat&w=1200&q=75',
        thumbnailColor: '#2770cf',
        thumbnailAlt: 'Thumbnail',
      },
      {
        title: `In ultrices mauris eu nisl molestie elementum`,
        slug: 'in-ultrices-mauris-eu-nisl-molestie-elementum',
        paragraphs: [
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed nisi mauris. Sed finibus nec dui in scelerisque. Aliquam a convallis leo, id blandit nisi. Phasellus dui libero, cursus in dui a, congue sagittis metus. Integer posuere lacus ut efficitur porttitor. Duis in nulla eu risus eleifend egestas. Donec viverra ultrices aliquet. Suspendisse facilisis quis ante ac commodo. Donec bibendum sodales purus non vehicula. Quisque dignissim augue ut ipsum tincidunt, id efficitur nibh feugiat. Ut non nisi arcu.',
          'Ut diam erat, consequat quis posuere vel, dignissim id lectus. Aliquam sollicitudin ante ut diam convallis accumsan. Curabitur eu sapien vitae enim imperdiet tempus. Aliquam iaculis sodales consequat. Maecenas faucibus sem ut facilisis pulvinar. Mauris pulvinar diam eget eros suscipit convallis sed molestie erat. Ut tristique suscipit turpis, eu tempus turpis volutpat non. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nullam blandit, libero quis sagittis accumsan, ligula odio tristique ex, eleifend blandit urna justo sit amet ante. Mauris non vestibulum turpis.',
          'Nam congue molestie libero sed bibendum. Fusce posuere eros ac mauris dapibus pharetra eu convallis felis. Etiam pellentesque ultricies rhoncus. Duis fermentum orci sem, nec tincidunt nibh viverra eu. Aenean dictum accumsan dictum. Nulla sit amet ipsum quam. Cras eu neque maximus, vulputate diam eu, consequat enim. Aliquam erat volutpat. Phasellus et rutrum magna.',
          'Vestibulum blandit nunc non sollicitudin imperdiet. In ultrices mauris eu nisl molestie elementum. In vel erat enim. Duis non magna sem. Quisque et egestas neque, vitae fermentum ante. Maecenas venenatis nibh eu facilisis egestas. Praesent iaculis rhoncus sodales. Aenean feugiat lectus nec tortor bibendum lobortis. Quisque velit nulla, pellentesque ac accumsan sed, sollicitudin id urna. Fusce ut dui urna. Phasellus dictum eget arcu vel tempor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi gravida non dolor quis aliquam. In hac habitasse platea dictumst. Mauris convallis ex at massa finibus, ac feugiat diam vestibulum. Phasellus ultricies est orci, suscipit elementum arcu dictum at.',
          'Sed quis tortor urna. Cras et eleifend arcu, nec semper sapien. Nunc vel facilisis turpis. Donec ullamcorper augue at nunc sollicitudin, quis consectetur velit accumsan. Sed quis pretium felis. Pellentesque consequat est a tempor ullamcorper. Quisque ullamcorper nisl in nisl laoreet, id tincidunt ligula posuere. Nam in auctor nibh. Nunc lobortis posuere tellus, malesuada posuere mi tempus eget. Quisque ut magna non risus faucibus commodo. In hac habitasse platea dictumst. Cras ut massa nisi.',
        ],
        thumbnailUrl:
          'https://stablo-pro.web3templates.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fcijrdavx%2Fproduction%2Fe60f8ab265df3c22fdde5469de54d225017b7323-4000x5000.jpg%3Fw%3D2000%26auto%3Dformat&w=1200&q=75',
        thumbnailColor: '#2770cf',
        thumbnailAlt: 'Thumbnail',
      },
    ],
  },
  {
    name: 'Food & Recipes',
    slug: 'food-recipes',
    description: 'Recipes, cooking tips, and restaurant reviews.',
    color: 'pink',
    subject: 'food',
    posts: [
      {
        title: `Lorem ipsum dolor sit amet, consectetur adipiscing elit`,
        slug: 'lorem-ipsum-dolor-sit-amet',
        paragraphs: [
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed nisi mauris. Sed finibus nec dui in scelerisque. Aliquam a convallis leo, id blandit nisi. Phasellus dui libero, cursus in dui a, congue sagittis metus. Integer posuere lacus ut efficitur porttitor. Duis in nulla eu risus eleifend egestas. Donec viverra ultrices aliquet. Suspendisse facilisis quis ante ac commodo. Donec bibendum sodales purus non vehicula. Quisque dignissim augue ut ipsum tincidunt, id efficitur nibh feugiat. Ut non nisi arcu.',
          'Ut diam erat, consequat quis posuere vel, dignissim id lectus. Aliquam sollicitudin ante ut diam convallis accumsan. Curabitur eu sapien vitae enim imperdiet tempus. Aliquam iaculis sodales consequat. Maecenas faucibus sem ut facilisis pulvinar. Mauris pulvinar diam eget eros suscipit convallis sed molestie erat. Ut tristique suscipit turpis, eu tempus turpis volutpat non. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nullam blandit, libero quis sagittis accumsan, ligula odio tristique ex, eleifend blandit urna justo sit amet ante. Mauris non vestibulum turpis.',
          'Nam congue molestie libero sed bibendum. Fusce posuere eros ac mauris dapibus pharetra eu convallis felis. Etiam pellentesque ultricies rhoncus. Duis fermentum orci sem, nec tincidunt nibh viverra eu. Aenean dictum accumsan dictum. Nulla sit amet ipsum quam. Cras eu neque maximus, vulputate diam eu, consequat enim. Aliquam erat volutpat. Phasellus et rutrum magna.',
          'Vestibulum blandit nunc non sollicitudin imperdiet. In ultrices mauris eu nisl molestie elementum. In vel erat enim. Duis non magna sem. Quisque et egestas neque, vitae fermentum ante. Maecenas venenatis nibh eu facilisis egestas. Praesent iaculis rhoncus sodales. Aenean feugiat lectus nec tortor bibendum lobortis. Quisque velit nulla, pellentesque ac accumsan sed, sollicitudin id urna. Fusce ut dui urna. Phasellus dictum eget arcu vel tempor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi gravida non dolor quis aliquam. In hac habitasse platea dictumst. Mauris convallis ex at massa finibus, ac feugiat diam vestibulum. Phasellus ultricies est orci, suscipit elementum arcu dictum at.',
          'Sed quis tortor urna. Cras et eleifend arcu, nec semper sapien. Nunc vel facilisis turpis. Donec ullamcorper augue at nunc sollicitudin, quis consectetur velit accumsan. Sed quis pretium felis. Pellentesque consequat est a tempor ullamcorper. Quisque ullamcorper nisl in nisl laoreet, id tincidunt ligula posuere. Nam in auctor nibh. Nunc lobortis posuere tellus, malesuada posuere mi tempus eget. Quisque ut magna non risus faucibus commodo. In hac habitasse platea dictumst. Cras ut massa nisi.',
        ],
        thumbnailUrl:
          'https://stablo-pro.web3templates.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fcijrdavx%2Fproduction%2F05951a0ec1a6ffc54f615ab160649e92fea982d0-800x764.png%3Frect%3D0%2C0%2C800%2C468%26w%3D800%26auto%3Dformat&w=1200&q=75',
        thumbnailColor: '#2770cf',
        thumbnailAlt: 'Thumbnail',
        featured: true,
      },
      {
        title: `Sed sit amet nulla lacus`,
        slug: 'sed-sit-amet-nulla-lacus',
        paragraphs: [
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed nisi mauris. Sed finibus nec dui in scelerisque. Aliquam a convallis leo, id blandit nisi. Phasellus dui libero, cursus in dui a, congue sagittis metus. Integer posuere lacus ut efficitur porttitor. Duis in nulla eu risus eleifend egestas. Donec viverra ultrices aliquet. Suspendisse facilisis quis ante ac commodo. Donec bibendum sodales purus non vehicula. Quisque dignissim augue ut ipsum tincidunt, id efficitur nibh feugiat. Ut non nisi arcu.',
          'Ut diam erat, consequat quis posuere vel, dignissim id lectus. Aliquam sollicitudin ante ut diam convallis accumsan. Curabitur eu sapien vitae enim imperdiet tempus. Aliquam iaculis sodales consequat. Maecenas faucibus sem ut facilisis pulvinar. Mauris pulvinar diam eget eros suscipit convallis sed molestie erat. Ut tristique suscipit turpis, eu tempus turpis volutpat non. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nullam blandit, libero quis sagittis accumsan, ligula odio tristique ex, eleifend blandit urna justo sit amet ante. Mauris non vestibulum turpis.',
          'Nam congue molestie libero sed bibendum. Fusce posuere eros ac mauris dapibus pharetra eu convallis felis. Etiam pellentesque ultricies rhoncus. Duis fermentum orci sem, nec tincidunt nibh viverra eu. Aenean dictum accumsan dictum. Nulla sit amet ipsum quam. Cras eu neque maximus, vulputate diam eu, consequat enim. Aliquam erat volutpat. Phasellus et rutrum magna.',
          'Vestibulum blandit nunc non sollicitudin imperdiet. In ultrices mauris eu nisl molestie elementum. In vel erat enim. Duis non magna sem. Quisque et egestas neque, vitae fermentum ante. Maecenas venenatis nibh eu facilisis egestas. Praesent iaculis rhoncus sodales. Aenean feugiat lectus nec tortor bibendum lobortis. Quisque velit nulla, pellentesque ac accumsan sed, sollicitudin id urna. Fusce ut dui urna. Phasellus dictum eget arcu vel tempor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi gravida non dolor quis aliquam. In hac habitasse platea dictumst. Mauris convallis ex at massa finibus, ac feugiat diam vestibulum. Phasellus ultricies est orci, suscipit elementum arcu dictum at.',
          'Sed quis tortor urna. Cras et eleifend arcu, nec semper sapien. Nunc vel facilisis turpis. Donec ullamcorper augue at nunc sollicitudin, quis consectetur velit accumsan. Sed quis pretium felis. Pellentesque consequat est a tempor ullamcorper. Quisque ullamcorper nisl in nisl laoreet, id tincidunt ligula posuere. Nam in auctor nibh. Nunc lobortis posuere tellus, malesuada posuere mi tempus eget. Quisque ut magna non risus faucibus commodo. In hac habitasse platea dictumst. Cras ut massa nisi.',
        ],
        thumbnailUrl:
          'https://stablo-pro.web3templates.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fcijrdavx%2Fproduction%2F2fda477a7e32f813abb9a8ef425939e6a91c7973-987x1481.png%3Frect%3D0%2C279%2C987%2C607%26w%3D987%26auto%3Dformat&w=1200&q=75',
        thumbnailColor: '#2770cf',
        thumbnailAlt: 'Thumbnail',
      },
      {
        title: `Fusce posuere eros ac mauris dapibus pharetra`,
        slug: 'fusce-posuere-eros-ac-mauris-dapibus-pharetra',
        paragraphs: [
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed nisi mauris. Sed finibus nec dui in scelerisque. Aliquam a convallis leo, id blandit nisi. Phasellus dui libero, cursus in dui a, congue sagittis metus. Integer posuere lacus ut efficitur porttitor. Duis in nulla eu risus eleifend egestas. Donec viverra ultrices aliquet. Suspendisse facilisis quis ante ac commodo. Donec bibendum sodales purus non vehicula. Quisque dignissim augue ut ipsum tincidunt, id efficitur nibh feugiat. Ut non nisi arcu.',
          'Ut diam erat, consequat quis posuere vel, dignissim id lectus. Aliquam sollicitudin ante ut diam convallis accumsan. Curabitur eu sapien vitae enim imperdiet tempus. Aliquam iaculis sodales consequat. Maecenas faucibus sem ut facilisis pulvinar. Mauris pulvinar diam eget eros suscipit convallis sed molestie erat. Ut tristique suscipit turpis, eu tempus turpis volutpat non. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nullam blandit, libero quis sagittis accumsan, ligula odio tristique ex, eleifend blandit urna justo sit amet ante. Mauris non vestibulum turpis.',
          'Nam congue molestie libero sed bibendum. Fusce posuere eros ac mauris dapibus pharetra eu convallis felis. Etiam pellentesque ultricies rhoncus. Duis fermentum orci sem, nec tincidunt nibh viverra eu. Aenean dictum accumsan dictum. Nulla sit amet ipsum quam. Cras eu neque maximus, vulputate diam eu, consequat enim. Aliquam erat volutpat. Phasellus et rutrum magna.',
          'Vestibulum blandit nunc non sollicitudin imperdiet. In ultrices mauris eu nisl molestie elementum. In vel erat enim. Duis non magna sem. Quisque et egestas neque, vitae fermentum ante. Maecenas venenatis nibh eu facilisis egestas. Praesent iaculis rhoncus sodales. Aenean feugiat lectus nec tortor bibendum lobortis. Quisque velit nulla, pellentesque ac accumsan sed, sollicitudin id urna. Fusce ut dui urna. Phasellus dictum eget arcu vel tempor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi gravida non dolor quis aliquam. In hac habitasse platea dictumst. Mauris convallis ex at massa finibus, ac feugiat diam vestibulum. Phasellus ultricies est orci, suscipit elementum arcu dictum at.',
          'Sed quis tortor urna. Cras et eleifend arcu, nec semper sapien. Nunc vel facilisis turpis. Donec ullamcorper augue at nunc sollicitudin, quis consectetur velit accumsan. Sed quis pretium felis. Pellentesque consequat est a tempor ullamcorper. Quisque ullamcorper nisl in nisl laoreet, id tincidunt ligula posuere. Nam in auctor nibh. Nunc lobortis posuere tellus, malesuada posuere mi tempus eget. Quisque ut magna non risus faucibus commodo. In hac habitasse platea dictumst. Cras ut massa nisi.',
        ],
        thumbnailUrl:
          'https://stablo-pro.web3templates.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fcijrdavx%2Fproduction%2Fa5bd8977e7fd82666c00a45948583b1f9912d392-3847x5583.jpg%3Fw%3D2000%26auto%3Dformat&w=1200&q=75',
        thumbnailColor: '#2770cf',
        thumbnailAlt: 'Thumbnail',
      },
      {
        title: `Nulla sit amet ipsum quam`,
        slug: 'nulla-sit-amet-ipsum-quam',
        paragraphs: [
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed nisi mauris. Sed finibus nec dui in scelerisque. Aliquam a convallis leo, id blandit nisi. Phasellus dui libero, cursus in dui a, congue sagittis metus. Integer posuere lacus ut efficitur porttitor. Duis in nulla eu risus eleifend egestas. Donec viverra ultrices aliquet. Suspendisse facilisis quis ante ac commodo. Donec bibendum sodales purus non vehicula. Quisque dignissim augue ut ipsum tincidunt, id efficitur nibh feugiat. Ut non nisi arcu.',
          'Ut diam erat, consequat quis posuere vel, dignissim id lectus. Aliquam sollicitudin ante ut diam convallis accumsan. Curabitur eu sapien vitae enim imperdiet tempus. Aliquam iaculis sodales consequat. Maecenas faucibus sem ut facilisis pulvinar. Mauris pulvinar diam eget eros suscipit convallis sed molestie erat. Ut tristique suscipit turpis, eu tempus turpis volutpat non. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nullam blandit, libero quis sagittis accumsan, ligula odio tristique ex, eleifend blandit urna justo sit amet ante. Mauris non vestibulum turpis.',
          'Nam congue molestie libero sed bibendum. Fusce posuere eros ac mauris dapibus pharetra eu convallis felis. Etiam pellentesque ultricies rhoncus. Duis fermentum orci sem, nec tincidunt nibh viverra eu. Aenean dictum accumsan dictum. Nulla sit amet ipsum quam. Cras eu neque maximus, vulputate diam eu, consequat enim. Aliquam erat volutpat. Phasellus et rutrum magna.',
          'Vestibulum blandit nunc non sollicitudin imperdiet. In ultrices mauris eu nisl molestie elementum. In vel erat enim. Duis non magna sem. Quisque et egestas neque, vitae fermentum ante. Maecenas venenatis nibh eu facilisis egestas. Praesent iaculis rhoncus sodales. Aenean feugiat lectus nec tortor bibendum lobortis. Quisque velit nulla, pellentesque ac accumsan sed, sollicitudin id urna. Fusce ut dui urna. Phasellus dictum eget arcu vel tempor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi gravida non dolor quis aliquam. In hac habitasse platea dictumst. Mauris convallis ex at massa finibus, ac feugiat diam vestibulum. Phasellus ultricies est orci, suscipit elementum arcu dictum at.',
          'Sed quis tortor urna. Cras et eleifend arcu, nec semper sapien. Nunc vel facilisis turpis. Donec ullamcorper augue at nunc sollicitudin, quis consectetur velit accumsan. Sed quis pretium felis. Pellentesque consequat est a tempor ullamcorper. Quisque ullamcorper nisl in nisl laoreet, id tincidunt ligula posuere. Nam in auctor nibh. Nunc lobortis posuere tellus, malesuada posuere mi tempus eget. Quisque ut magna non risus faucibus commodo. In hac habitasse platea dictumst. Cras ut massa nisi.',
        ],
        thumbnailUrl:
          'https://stablo-pro.web3templates.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fcijrdavx%2Fproduction%2F279c03681911845947cd044b4ac9e91d7a3a628c-987x1481.png%3Fw%3D987%26auto%3Dformat&w=1200&q=75',
        thumbnailColor: '#2770cf',
        thumbnailAlt: 'Thumbnail',
      },
      {
        title: `Cras eu neque maximus, vulputate diam eu, consequat enim`,
        slug: 'cras-eu-neque-maximus-vulputate-diam-eu-consequat-enim',
        paragraphs: [
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed nisi mauris. Sed finibus nec dui in scelerisque. Aliquam a convallis leo, id blandit nisi. Phasellus dui libero, cursus in dui a, congue sagittis metus. Integer posuere lacus ut efficitur porttitor. Duis in nulla eu risus eleifend egestas. Donec viverra ultrices aliquet. Suspendisse facilisis quis ante ac commodo. Donec bibendum sodales purus non vehicula. Quisque dignissim augue ut ipsum tincidunt, id efficitur nibh feugiat. Ut non nisi arcu.',
          'Ut diam erat, consequat quis posuere vel, dignissim id lectus. Aliquam sollicitudin ante ut diam convallis accumsan. Curabitur eu sapien vitae enim imperdiet tempus. Aliquam iaculis sodales consequat. Maecenas faucibus sem ut facilisis pulvinar. Mauris pulvinar diam eget eros suscipit convallis sed molestie erat. Ut tristique suscipit turpis, eu tempus turpis volutpat non. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nullam blandit, libero quis sagittis accumsan, ligula odio tristique ex, eleifend blandit urna justo sit amet ante. Mauris non vestibulum turpis.',
          'Nam congue molestie libero sed bibendum. Fusce posuere eros ac mauris dapibus pharetra eu convallis felis. Etiam pellentesque ultricies rhoncus. Duis fermentum orci sem, nec tincidunt nibh viverra eu. Aenean dictum accumsan dictum. Nulla sit amet ipsum quam. Cras eu neque maximus, vulputate diam eu, consequat enim. Aliquam erat volutpat. Phasellus et rutrum magna.',
          'Vestibulum blandit nunc non sollicitudin imperdiet. In ultrices mauris eu nisl molestie elementum. In vel erat enim. Duis non magna sem. Quisque et egestas neque, vitae fermentum ante. Maecenas venenatis nibh eu facilisis egestas. Praesent iaculis rhoncus sodales. Aenean feugiat lectus nec tortor bibendum lobortis. Quisque velit nulla, pellentesque ac accumsan sed, sollicitudin id urna. Fusce ut dui urna. Phasellus dictum eget arcu vel tempor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi gravida non dolor quis aliquam. In hac habitasse platea dictumst. Mauris convallis ex at massa finibus, ac feugiat diam vestibulum. Phasellus ultricies est orci, suscipit elementum arcu dictum at.',
          'Sed quis tortor urna. Cras et eleifend arcu, nec semper sapien. Nunc vel facilisis turpis. Donec ullamcorper augue at nunc sollicitudin, quis consectetur velit accumsan. Sed quis pretium felis. Pellentesque consequat est a tempor ullamcorper. Quisque ullamcorper nisl in nisl laoreet, id tincidunt ligula posuere. Nam in auctor nibh. Nunc lobortis posuere tellus, malesuada posuere mi tempus eget. Quisque ut magna non risus faucibus commodo. In hac habitasse platea dictumst. Cras ut massa nisi.',
        ],
        thumbnailUrl:
          'https://stablo-pro.web3templates.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fcijrdavx%2Fproduction%2F123fb8836aaf097b83d4c5e8fde544dd1747af0d-5064x3376.jpg%3Fw%3D2000%26auto%3Dformat&w=1200&q=75',
        thumbnailColor: '#2770cf',
        thumbnailAlt: 'Thumbnail',
      },
      {
        title: `Aliquam erat volutpat`,
        slug: 'aliquam-erat-volutpat',
        paragraphs: [
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed nisi mauris. Sed finibus nec dui in scelerisque. Aliquam a convallis leo, id blandit nisi. Phasellus dui libero, cursus in dui a, congue sagittis metus. Integer posuere lacus ut efficitur porttitor. Duis in nulla eu risus eleifend egestas. Donec viverra ultrices aliquet. Suspendisse facilisis quis ante ac commodo. Donec bibendum sodales purus non vehicula. Quisque dignissim augue ut ipsum tincidunt, id efficitur nibh feugiat. Ut non nisi arcu.',
          'Ut diam erat, consequat quis posuere vel, dignissim id lectus. Aliquam sollicitudin ante ut diam convallis accumsan. Curabitur eu sapien vitae enim imperdiet tempus. Aliquam iaculis sodales consequat. Maecenas faucibus sem ut facilisis pulvinar. Mauris pulvinar diam eget eros suscipit convallis sed molestie erat. Ut tristique suscipit turpis, eu tempus turpis volutpat non. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nullam blandit, libero quis sagittis accumsan, ligula odio tristique ex, eleifend blandit urna justo sit amet ante. Mauris non vestibulum turpis.',
          'Nam congue molestie libero sed bibendum. Fusce posuere eros ac mauris dapibus pharetra eu convallis felis. Etiam pellentesque ultricies rhoncus. Duis fermentum orci sem, nec tincidunt nibh viverra eu. Aenean dictum accumsan dictum. Nulla sit amet ipsum quam. Cras eu neque maximus, vulputate diam eu, consequat enim. Aliquam erat volutpat. Phasellus et rutrum magna.',
          'Vestibulum blandit nunc non sollicitudin imperdiet. In ultrices mauris eu nisl molestie elementum. In vel erat enim. Duis non magna sem. Quisque et egestas neque, vitae fermentum ante. Maecenas venenatis nibh eu facilisis egestas. Praesent iaculis rhoncus sodales. Aenean feugiat lectus nec tortor bibendum lobortis. Quisque velit nulla, pellentesque ac accumsan sed, sollicitudin id urna. Fusce ut dui urna. Phasellus dictum eget arcu vel tempor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi gravida non dolor quis aliquam. In hac habitasse platea dictumst. Mauris convallis ex at massa finibus, ac feugiat diam vestibulum. Phasellus ultricies est orci, suscipit elementum arcu dictum at.',
          'Sed quis tortor urna. Cras et eleifend arcu, nec semper sapien. Nunc vel facilisis turpis. Donec ullamcorper augue at nunc sollicitudin, quis consectetur velit accumsan. Sed quis pretium felis. Pellentesque consequat est a tempor ullamcorper. Quisque ullamcorper nisl in nisl laoreet, id tincidunt ligula posuere. Nam in auctor nibh. Nunc lobortis posuere tellus, malesuada posuere mi tempus eget. Quisque ut magna non risus faucibus commodo. In hac habitasse platea dictumst. Cras ut massa nisi.',
        ],
        thumbnailUrl:
          'https://stablo-pro.web3templates.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fcijrdavx%2Fproduction%2F5be1635417f1814b3fb09f8e9f74f37079899f72-4032x3024.jpg%3Fw%3D2000%26auto%3Dformat&w=1200&q=75',
        thumbnailColor: '#2770cf',
        thumbnailAlt: 'Thumbnail',
      },
      {
        title: `Phasellus et rutrum magna`,
        slug: 'phasellus-et-rutrum-magna',
        paragraphs: [
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed nisi mauris. Sed finibus nec dui in scelerisque. Aliquam a convallis leo, id blandit nisi. Phasellus dui libero, cursus in dui a, congue sagittis metus. Integer posuere lacus ut efficitur porttitor. Duis in nulla eu risus eleifend egestas. Donec viverra ultrices aliquet. Suspendisse facilisis quis ante ac commodo. Donec bibendum sodales purus non vehicula. Quisque dignissim augue ut ipsum tincidunt, id efficitur nibh feugiat. Ut non nisi arcu.',
          'Ut diam erat, consequat quis posuere vel, dignissim id lectus. Aliquam sollicitudin ante ut diam convallis accumsan. Curabitur eu sapien vitae enim imperdiet tempus. Aliquam iaculis sodales consequat. Maecenas faucibus sem ut facilisis pulvinar. Mauris pulvinar diam eget eros suscipit convallis sed molestie erat. Ut tristique suscipit turpis, eu tempus turpis volutpat non. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nullam blandit, libero quis sagittis accumsan, ligula odio tristique ex, eleifend blandit urna justo sit amet ante. Mauris non vestibulum turpis.',
          'Nam congue molestie libero sed bibendum. Fusce posuere eros ac mauris dapibus pharetra eu convallis felis. Etiam pellentesque ultricies rhoncus. Duis fermentum orci sem, nec tincidunt nibh viverra eu. Aenean dictum accumsan dictum. Nulla sit amet ipsum quam. Cras eu neque maximus, vulputate diam eu, consequat enim. Aliquam erat volutpat. Phasellus et rutrum magna.',
          'Vestibulum blandit nunc non sollicitudin imperdiet. In ultrices mauris eu nisl molestie elementum. In vel erat enim. Duis non magna sem. Quisque et egestas neque, vitae fermentum ante. Maecenas venenatis nibh eu facilisis egestas. Praesent iaculis rhoncus sodales. Aenean feugiat lectus nec tortor bibendum lobortis. Quisque velit nulla, pellentesque ac accumsan sed, sollicitudin id urna. Fusce ut dui urna. Phasellus dictum eget arcu vel tempor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi gravida non dolor quis aliquam. In hac habitasse platea dictumst. Mauris convallis ex at massa finibus, ac feugiat diam vestibulum. Phasellus ultricies est orci, suscipit elementum arcu dictum at.',
          'Sed quis tortor urna. Cras et eleifend arcu, nec semper sapien. Nunc vel facilisis turpis. Donec ullamcorper augue at nunc sollicitudin, quis consectetur velit accumsan. Sed quis pretium felis. Pellentesque consequat est a tempor ullamcorper. Quisque ullamcorper nisl in nisl laoreet, id tincidunt ligula posuere. Nam in auctor nibh. Nunc lobortis posuere tellus, malesuada posuere mi tempus eget. Quisque ut magna non risus faucibus commodo. In hac habitasse platea dictumst. Cras ut massa nisi.',
        ],
        thumbnailUrl:
          'https://stablo-pro.web3templates.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fcijrdavx%2Fproduction%2Fd4898616e2973584b1b8a0bfdb5c6cf7c36ab012-1064x1330.png%3Fw%3D1064%26auto%3Dformat&w=1200&q=75',
        thumbnailColor: '#2770cf',
        thumbnailAlt: 'Thumbnail',
      },
    ],
  },
  {
    name: 'Lifestyle',
    slug: 'lifestyle',
    description:
      'Articles on fashion, interior design, hobbies, and personal development.',
    color: 'orange',
    subject: 'lifestyle',
    posts: [
      {
        title: `Lorem ipsum dolor sit amet, consectetur adipiscing elit`,
        slug: 'lorem-ipsum-dolor-sit-amet',
        paragraphs: [
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed nisi mauris. Sed finibus nec dui in scelerisque. Aliquam a convallis leo, id blandit nisi. Phasellus dui libero, cursus in dui a, congue sagittis metus. Integer posuere lacus ut efficitur porttitor. Duis in nulla eu risus eleifend egestas. Donec viverra ultrices aliquet. Suspendisse facilisis quis ante ac commodo. Donec bibendum sodales purus non vehicula. Quisque dignissim augue ut ipsum tincidunt, id efficitur nibh feugiat. Ut non nisi arcu.',
          'Ut diam erat, consequat quis posuere vel, dignissim id lectus. Aliquam sollicitudin ante ut diam convallis accumsan. Curabitur eu sapien vitae enim imperdiet tempus. Aliquam iaculis sodales consequat. Maecenas faucibus sem ut facilisis pulvinar. Mauris pulvinar diam eget eros suscipit convallis sed molestie erat. Ut tristique suscipit turpis, eu tempus turpis volutpat non. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nullam blandit, libero quis sagittis accumsan, ligula odio tristique ex, eleifend blandit urna justo sit amet ante. Mauris non vestibulum turpis.',
          'Nam congue molestie libero sed bibendum. Fusce posuere eros ac mauris dapibus pharetra eu convallis felis. Etiam pellentesque ultricies rhoncus. Duis fermentum orci sem, nec tincidunt nibh viverra eu. Aenean dictum accumsan dictum. Nulla sit amet ipsum quam. Cras eu neque maximus, vulputate diam eu, consequat enim. Aliquam erat volutpat. Phasellus et rutrum magna.',
          'Vestibulum blandit nunc non sollicitudin imperdiet. In ultrices mauris eu nisl molestie elementum. In vel erat enim. Duis non magna sem. Quisque et egestas neque, vitae fermentum ante. Maecenas venenatis nibh eu facilisis egestas. Praesent iaculis rhoncus sodales. Aenean feugiat lectus nec tortor bibendum lobortis. Quisque velit nulla, pellentesque ac accumsan sed, sollicitudin id urna. Fusce ut dui urna. Phasellus dictum eget arcu vel tempor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi gravida non dolor quis aliquam. In hac habitasse platea dictumst. Mauris convallis ex at massa finibus, ac feugiat diam vestibulum. Phasellus ultricies est orci, suscipit elementum arcu dictum at.',
          'Sed quis tortor urna. Cras et eleifend arcu, nec semper sapien. Nunc vel facilisis turpis. Donec ullamcorper augue at nunc sollicitudin, quis consectetur velit accumsan. Sed quis pretium felis. Pellentesque consequat est a tempor ullamcorper. Quisque ullamcorper nisl in nisl laoreet, id tincidunt ligula posuere. Nam in auctor nibh. Nunc lobortis posuere tellus, malesuada posuere mi tempus eget. Quisque ut magna non risus faucibus commodo. In hac habitasse platea dictumst. Cras ut massa nisi.',
        ],
        thumbnailUrl:
          'https://stablo-pro.web3templates.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fcijrdavx%2Fproduction%2F05951a0ec1a6ffc54f615ab160649e92fea982d0-800x764.png%3Frect%3D0%2C0%2C800%2C468%26w%3D800%26auto%3Dformat&w=1200&q=75',
        thumbnailColor: '#2770cf',
        thumbnailAlt: 'Thumbnail',
        featured: true,
      },
      {
        title: `Sed sit amet nulla lacus`,
        slug: 'sed-sit-amet-nulla-lacus',
        paragraphs: [
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed nisi mauris. Sed finibus nec dui in scelerisque. Aliquam a convallis leo, id blandit nisi. Phasellus dui libero, cursus in dui a, congue sagittis metus. Integer posuere lacus ut efficitur porttitor. Duis in nulla eu risus eleifend egestas. Donec viverra ultrices aliquet. Suspendisse facilisis quis ante ac commodo. Donec bibendum sodales purus non vehicula. Quisque dignissim augue ut ipsum tincidunt, id efficitur nibh feugiat. Ut non nisi arcu.',
          'Ut diam erat, consequat quis posuere vel, dignissim id lectus. Aliquam sollicitudin ante ut diam convallis accumsan. Curabitur eu sapien vitae enim imperdiet tempus. Aliquam iaculis sodales consequat. Maecenas faucibus sem ut facilisis pulvinar. Mauris pulvinar diam eget eros suscipit convallis sed molestie erat. Ut tristique suscipit turpis, eu tempus turpis volutpat non. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nullam blandit, libero quis sagittis accumsan, ligula odio tristique ex, eleifend blandit urna justo sit amet ante. Mauris non vestibulum turpis.',
          'Nam congue molestie libero sed bibendum. Fusce posuere eros ac mauris dapibus pharetra eu convallis felis. Etiam pellentesque ultricies rhoncus. Duis fermentum orci sem, nec tincidunt nibh viverra eu. Aenean dictum accumsan dictum. Nulla sit amet ipsum quam. Cras eu neque maximus, vulputate diam eu, consequat enim. Aliquam erat volutpat. Phasellus et rutrum magna.',
          'Vestibulum blandit nunc non sollicitudin imperdiet. In ultrices mauris eu nisl molestie elementum. In vel erat enim. Duis non magna sem. Quisque et egestas neque, vitae fermentum ante. Maecenas venenatis nibh eu facilisis egestas. Praesent iaculis rhoncus sodales. Aenean feugiat lectus nec tortor bibendum lobortis. Quisque velit nulla, pellentesque ac accumsan sed, sollicitudin id urna. Fusce ut dui urna. Phasellus dictum eget arcu vel tempor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi gravida non dolor quis aliquam. In hac habitasse platea dictumst. Mauris convallis ex at massa finibus, ac feugiat diam vestibulum. Phasellus ultricies est orci, suscipit elementum arcu dictum at.',
          'Sed quis tortor urna. Cras et eleifend arcu, nec semper sapien. Nunc vel facilisis turpis. Donec ullamcorper augue at nunc sollicitudin, quis consectetur velit accumsan. Sed quis pretium felis. Pellentesque consequat est a tempor ullamcorper. Quisque ullamcorper nisl in nisl laoreet, id tincidunt ligula posuere. Nam in auctor nibh. Nunc lobortis posuere tellus, malesuada posuere mi tempus eget. Quisque ut magna non risus faucibus commodo. In hac habitasse platea dictumst. Cras ut massa nisi.',
        ],
        thumbnailUrl:
          'https://stablo-pro.web3templates.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fcijrdavx%2Fproduction%2F2fda477a7e32f813abb9a8ef425939e6a91c7973-987x1481.png%3Frect%3D0%2C279%2C987%2C607%26w%3D987%26auto%3Dformat&w=1200&q=75',
        thumbnailColor: '#2770cf',
        thumbnailAlt: 'Thumbnail',
      },
      {
        title: `Fusce posuere eros ac mauris dapibus pharetra`,
        slug: 'fusce-posuere-eros-ac-mauris-dapibus-pharetra',
        paragraphs: [
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed nisi mauris. Sed finibus nec dui in scelerisque. Aliquam a convallis leo, id blandit nisi. Phasellus dui libero, cursus in dui a, congue sagittis metus. Integer posuere lacus ut efficitur porttitor. Duis in nulla eu risus eleifend egestas. Donec viverra ultrices aliquet. Suspendisse facilisis quis ante ac commodo. Donec bibendum sodales purus non vehicula. Quisque dignissim augue ut ipsum tincidunt, id efficitur nibh feugiat. Ut non nisi arcu.',
          'Ut diam erat, consequat quis posuere vel, dignissim id lectus. Aliquam sollicitudin ante ut diam convallis accumsan. Curabitur eu sapien vitae enim imperdiet tempus. Aliquam iaculis sodales consequat. Maecenas faucibus sem ut facilisis pulvinar. Mauris pulvinar diam eget eros suscipit convallis sed molestie erat. Ut tristique suscipit turpis, eu tempus turpis volutpat non. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nullam blandit, libero quis sagittis accumsan, ligula odio tristique ex, eleifend blandit urna justo sit amet ante. Mauris non vestibulum turpis.',
          'Nam congue molestie libero sed bibendum. Fusce posuere eros ac mauris dapibus pharetra eu convallis felis. Etiam pellentesque ultricies rhoncus. Duis fermentum orci sem, nec tincidunt nibh viverra eu. Aenean dictum accumsan dictum. Nulla sit amet ipsum quam. Cras eu neque maximus, vulputate diam eu, consequat enim. Aliquam erat volutpat. Phasellus et rutrum magna.',
          'Vestibulum blandit nunc non sollicitudin imperdiet. In ultrices mauris eu nisl molestie elementum. In vel erat enim. Duis non magna sem. Quisque et egestas neque, vitae fermentum ante. Maecenas venenatis nibh eu facilisis egestas. Praesent iaculis rhoncus sodales. Aenean feugiat lectus nec tortor bibendum lobortis. Quisque velit nulla, pellentesque ac accumsan sed, sollicitudin id urna. Fusce ut dui urna. Phasellus dictum eget arcu vel tempor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi gravida non dolor quis aliquam. In hac habitasse platea dictumst. Mauris convallis ex at massa finibus, ac feugiat diam vestibulum. Phasellus ultricies est orci, suscipit elementum arcu dictum at.',
          'Sed quis tortor urna. Cras et eleifend arcu, nec semper sapien. Nunc vel facilisis turpis. Donec ullamcorper augue at nunc sollicitudin, quis consectetur velit accumsan. Sed quis pretium felis. Pellentesque consequat est a tempor ullamcorper. Quisque ullamcorper nisl in nisl laoreet, id tincidunt ligula posuere. Nam in auctor nibh. Nunc lobortis posuere tellus, malesuada posuere mi tempus eget. Quisque ut magna non risus faucibus commodo. In hac habitasse platea dictumst. Cras ut massa nisi.',
        ],
        thumbnailUrl:
          'https://stablo-pro.web3templates.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fcijrdavx%2Fproduction%2Fa5bd8977e7fd82666c00a45948583b1f9912d392-3847x5583.jpg%3Fw%3D2000%26auto%3Dformat&w=1200&q=75',
        thumbnailColor: '#2770cf',
        thumbnailAlt: 'Thumbnail',
      },
      {
        title: `Nulla sit amet ipsum quam`,
        slug: 'nulla-sit-amet-ipsum-quam',
        paragraphs: [
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed nisi mauris. Sed finibus nec dui in scelerisque. Aliquam a convallis leo, id blandit nisi. Phasellus dui libero, cursus in dui a, congue sagittis metus. Integer posuere lacus ut efficitur porttitor. Duis in nulla eu risus eleifend egestas. Donec viverra ultrices aliquet. Suspendisse facilisis quis ante ac commodo. Donec bibendum sodales purus non vehicula. Quisque dignissim augue ut ipsum tincidunt, id efficitur nibh feugiat. Ut non nisi arcu.',
          'Ut diam erat, consequat quis posuere vel, dignissim id lectus. Aliquam sollicitudin ante ut diam convallis accumsan. Curabitur eu sapien vitae enim imperdiet tempus. Aliquam iaculis sodales consequat. Maecenas faucibus sem ut facilisis pulvinar. Mauris pulvinar diam eget eros suscipit convallis sed molestie erat. Ut tristique suscipit turpis, eu tempus turpis volutpat non. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nullam blandit, libero quis sagittis accumsan, ligula odio tristique ex, eleifend blandit urna justo sit amet ante. Mauris non vestibulum turpis.',
          'Nam congue molestie libero sed bibendum. Fusce posuere eros ac mauris dapibus pharetra eu convallis felis. Etiam pellentesque ultricies rhoncus. Duis fermentum orci sem, nec tincidunt nibh viverra eu. Aenean dictum accumsan dictum. Nulla sit amet ipsum quam. Cras eu neque maximus, vulputate diam eu, consequat enim. Aliquam erat volutpat. Phasellus et rutrum magna.',
          'Vestibulum blandit nunc non sollicitudin imperdiet. In ultrices mauris eu nisl molestie elementum. In vel erat enim. Duis non magna sem. Quisque et egestas neque, vitae fermentum ante. Maecenas venenatis nibh eu facilisis egestas. Praesent iaculis rhoncus sodales. Aenean feugiat lectus nec tortor bibendum lobortis. Quisque velit nulla, pellentesque ac accumsan sed, sollicitudin id urna. Fusce ut dui urna. Phasellus dictum eget arcu vel tempor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi gravida non dolor quis aliquam. In hac habitasse platea dictumst. Mauris convallis ex at massa finibus, ac feugiat diam vestibulum. Phasellus ultricies est orci, suscipit elementum arcu dictum at.',
          'Sed quis tortor urna. Cras et eleifend arcu, nec semper sapien. Nunc vel facilisis turpis. Donec ullamcorper augue at nunc sollicitudin, quis consectetur velit accumsan. Sed quis pretium felis. Pellentesque consequat est a tempor ullamcorper. Quisque ullamcorper nisl in nisl laoreet, id tincidunt ligula posuere. Nam in auctor nibh. Nunc lobortis posuere tellus, malesuada posuere mi tempus eget. Quisque ut magna non risus faucibus commodo. In hac habitasse platea dictumst. Cras ut massa nisi.',
        ],
        thumbnailUrl:
          'https://stablo-pro.web3templates.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fcijrdavx%2Fproduction%2F279c03681911845947cd044b4ac9e91d7a3a628c-987x1481.png%3Fw%3D987%26auto%3Dformat&w=1200&q=75',
        thumbnailColor: '#2770cf',
        thumbnailAlt: 'Thumbnail',
      },
      {
        title: `Cras eu neque maximus, vulputate diam eu, consequat enim`,
        slug: 'cras-eu-neque-maximus-vulputate-diam-eu-consequat-enim',
        paragraphs: [
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed nisi mauris. Sed finibus nec dui in scelerisque. Aliquam a convallis leo, id blandit nisi. Phasellus dui libero, cursus in dui a, congue sagittis metus. Integer posuere lacus ut efficitur porttitor. Duis in nulla eu risus eleifend egestas. Donec viverra ultrices aliquet. Suspendisse facilisis quis ante ac commodo. Donec bibendum sodales purus non vehicula. Quisque dignissim augue ut ipsum tincidunt, id efficitur nibh feugiat. Ut non nisi arcu.',
          'Ut diam erat, consequat quis posuere vel, dignissim id lectus. Aliquam sollicitudin ante ut diam convallis accumsan. Curabitur eu sapien vitae enim imperdiet tempus. Aliquam iaculis sodales consequat. Maecenas faucibus sem ut facilisis pulvinar. Mauris pulvinar diam eget eros suscipit convallis sed molestie erat. Ut tristique suscipit turpis, eu tempus turpis volutpat non. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nullam blandit, libero quis sagittis accumsan, ligula odio tristique ex, eleifend blandit urna justo sit amet ante. Mauris non vestibulum turpis.',
          'Nam congue molestie libero sed bibendum. Fusce posuere eros ac mauris dapibus pharetra eu convallis felis. Etiam pellentesque ultricies rhoncus. Duis fermentum orci sem, nec tincidunt nibh viverra eu. Aenean dictum accumsan dictum. Nulla sit amet ipsum quam. Cras eu neque maximus, vulputate diam eu, consequat enim. Aliquam erat volutpat. Phasellus et rutrum magna.',
          'Vestibulum blandit nunc non sollicitudin imperdiet. In ultrices mauris eu nisl molestie elementum. In vel erat enim. Duis non magna sem. Quisque et egestas neque, vitae fermentum ante. Maecenas venenatis nibh eu facilisis egestas. Praesent iaculis rhoncus sodales. Aenean feugiat lectus nec tortor bibendum lobortis. Quisque velit nulla, pellentesque ac accumsan sed, sollicitudin id urna. Fusce ut dui urna. Phasellus dictum eget arcu vel tempor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi gravida non dolor quis aliquam. In hac habitasse platea dictumst. Mauris convallis ex at massa finibus, ac feugiat diam vestibulum. Phasellus ultricies est orci, suscipit elementum arcu dictum at.',
          'Sed quis tortor urna. Cras et eleifend arcu, nec semper sapien. Nunc vel facilisis turpis. Donec ullamcorper augue at nunc sollicitudin, quis consectetur velit accumsan. Sed quis pretium felis. Pellentesque consequat est a tempor ullamcorper. Quisque ullamcorper nisl in nisl laoreet, id tincidunt ligula posuere. Nam in auctor nibh. Nunc lobortis posuere tellus, malesuada posuere mi tempus eget. Quisque ut magna non risus faucibus commodo. In hac habitasse platea dictumst. Cras ut massa nisi.',
        ],
        thumbnailUrl:
          'https://stablo-pro.web3templates.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fcijrdavx%2Fproduction%2F123fb8836aaf097b83d4c5e8fde544dd1747af0d-5064x3376.jpg%3Fw%3D2000%26auto%3Dformat&w=1200&q=75',
        thumbnailColor: '#2770cf',
        thumbnailAlt: 'Thumbnail',
      },
    ],
  },
  {
    name: 'Education & Career',
    slug: 'education-career',
    description:
      'Advice for studies, professional training, and career management.',
    color: 'blue',
    subject: 'education',
    posts: [
      {
        title: `Lorem ipsum dolor sit amet, consectetur adipiscing elit`,
        slug: 'lorem-ipsum-dolor-sit-amet',
        paragraphs: [
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed nisi mauris. Sed finibus nec dui in scelerisque. Aliquam a convallis leo, id blandit nisi. Phasellus dui libero, cursus in dui a, congue sagittis metus. Integer posuere lacus ut efficitur porttitor. Duis in nulla eu risus eleifend egestas. Donec viverra ultrices aliquet. Suspendisse facilisis quis ante ac commodo. Donec bibendum sodales purus non vehicula. Quisque dignissim augue ut ipsum tincidunt, id efficitur nibh feugiat. Ut non nisi arcu.',
          'Ut diam erat, consequat quis posuere vel, dignissim id lectus. Aliquam sollicitudin ante ut diam convallis accumsan. Curabitur eu sapien vitae enim imperdiet tempus. Aliquam iaculis sodales consequat. Maecenas faucibus sem ut facilisis pulvinar. Mauris pulvinar diam eget eros suscipit convallis sed molestie erat. Ut tristique suscipit turpis, eu tempus turpis volutpat non. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nullam blandit, libero quis sagittis accumsan, ligula odio tristique ex, eleifend blandit urna justo sit amet ante. Mauris non vestibulum turpis.',
          'Nam congue molestie libero sed bibendum. Fusce posuere eros ac mauris dapibus pharetra eu convallis felis. Etiam pellentesque ultricies rhoncus. Duis fermentum orci sem, nec tincidunt nibh viverra eu. Aenean dictum accumsan dictum. Nulla sit amet ipsum quam. Cras eu neque maximus, vulputate diam eu, consequat enim. Aliquam erat volutpat. Phasellus et rutrum magna.',
          'Vestibulum blandit nunc non sollicitudin imperdiet. In ultrices mauris eu nisl molestie elementum. In vel erat enim. Duis non magna sem. Quisque et egestas neque, vitae fermentum ante. Maecenas venenatis nibh eu facilisis egestas. Praesent iaculis rhoncus sodales. Aenean feugiat lectus nec tortor bibendum lobortis. Quisque velit nulla, pellentesque ac accumsan sed, sollicitudin id urna. Fusce ut dui urna. Phasellus dictum eget arcu vel tempor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi gravida non dolor quis aliquam. In hac habitasse platea dictumst. Mauris convallis ex at massa finibus, ac feugiat diam vestibulum. Phasellus ultricies est orci, suscipit elementum arcu dictum at.',
          'Sed quis tortor urna. Cras et eleifend arcu, nec semper sapien. Nunc vel facilisis turpis. Donec ullamcorper augue at nunc sollicitudin, quis consectetur velit accumsan. Sed quis pretium felis. Pellentesque consequat est a tempor ullamcorper. Quisque ullamcorper nisl in nisl laoreet, id tincidunt ligula posuere. Nam in auctor nibh. Nunc lobortis posuere tellus, malesuada posuere mi tempus eget. Quisque ut magna non risus faucibus commodo. In hac habitasse platea dictumst. Cras ut massa nisi.',
        ],
        thumbnailUrl:
          'https://stablo-pro.web3templates.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fcijrdavx%2Fproduction%2F05951a0ec1a6ffc54f615ab160649e92fea982d0-800x764.png%3Frect%3D0%2C0%2C800%2C468%26w%3D800%26auto%3Dformat&w=1200&q=75',
        thumbnailColor: '#2770cf',
        thumbnailAlt: 'Thumbnail',
        featured: true,
      },
      {
        title: `Sed sit amet nulla lacus`,
        slug: 'sed-sit-amet-nulla-lacus',
        paragraphs: [
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed nisi mauris. Sed finibus nec dui in scelerisque. Aliquam a convallis leo, id blandit nisi. Phasellus dui libero, cursus in dui a, congue sagittis metus. Integer posuere lacus ut efficitur porttitor. Duis in nulla eu risus eleifend egestas. Donec viverra ultrices aliquet. Suspendisse facilisis quis ante ac commodo. Donec bibendum sodales purus non vehicula. Quisque dignissim augue ut ipsum tincidunt, id efficitur nibh feugiat. Ut non nisi arcu.',
          'Ut diam erat, consequat quis posuere vel, dignissim id lectus. Aliquam sollicitudin ante ut diam convallis accumsan. Curabitur eu sapien vitae enim imperdiet tempus. Aliquam iaculis sodales consequat. Maecenas faucibus sem ut facilisis pulvinar. Mauris pulvinar diam eget eros suscipit convallis sed molestie erat. Ut tristique suscipit turpis, eu tempus turpis volutpat non. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nullam blandit, libero quis sagittis accumsan, ligula odio tristique ex, eleifend blandit urna justo sit amet ante. Mauris non vestibulum turpis.',
          'Nam congue molestie libero sed bibendum. Fusce posuere eros ac mauris dapibus pharetra eu convallis felis. Etiam pellentesque ultricies rhoncus. Duis fermentum orci sem, nec tincidunt nibh viverra eu. Aenean dictum accumsan dictum. Nulla sit amet ipsum quam. Cras eu neque maximus, vulputate diam eu, consequat enim. Aliquam erat volutpat. Phasellus et rutrum magna.',
          'Vestibulum blandit nunc non sollicitudin imperdiet. In ultrices mauris eu nisl molestie elementum. In vel erat enim. Duis non magna sem. Quisque et egestas neque, vitae fermentum ante. Maecenas venenatis nibh eu facilisis egestas. Praesent iaculis rhoncus sodales. Aenean feugiat lectus nec tortor bibendum lobortis. Quisque velit nulla, pellentesque ac accumsan sed, sollicitudin id urna. Fusce ut dui urna. Phasellus dictum eget arcu vel tempor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi gravida non dolor quis aliquam. In hac habitasse platea dictumst. Mauris convallis ex at massa finibus, ac feugiat diam vestibulum. Phasellus ultricies est orci, suscipit elementum arcu dictum at.',
          'Sed quis tortor urna. Cras et eleifend arcu, nec semper sapien. Nunc vel facilisis turpis. Donec ullamcorper augue at nunc sollicitudin, quis consectetur velit accumsan. Sed quis pretium felis. Pellentesque consequat est a tempor ullamcorper. Quisque ullamcorper nisl in nisl laoreet, id tincidunt ligula posuere. Nam in auctor nibh. Nunc lobortis posuere tellus, malesuada posuere mi tempus eget. Quisque ut magna non risus faucibus commodo. In hac habitasse platea dictumst. Cras ut massa nisi.',
        ],
        thumbnailUrl:
          'https://stablo-pro.web3templates.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fcijrdavx%2Fproduction%2F2fda477a7e32f813abb9a8ef425939e6a91c7973-987x1481.png%3Frect%3D0%2C279%2C987%2C607%26w%3D987%26auto%3Dformat&w=1200&q=75',
        thumbnailColor: '#2770cf',
        thumbnailAlt: 'Thumbnail',
      },
      {
        title: `Fusce posuere eros ac mauris dapibus pharetra`,
        slug: 'fusce-posuere-eros-ac-mauris-dapibus-pharetra',
        paragraphs: [
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed nisi mauris. Sed finibus nec dui in scelerisque. Aliquam a convallis leo, id blandit nisi. Phasellus dui libero, cursus in dui a, congue sagittis metus. Integer posuere lacus ut efficitur porttitor. Duis in nulla eu risus eleifend egestas. Donec viverra ultrices aliquet. Suspendisse facilisis quis ante ac commodo. Donec bibendum sodales purus non vehicula. Quisque dignissim augue ut ipsum tincidunt, id efficitur nibh feugiat. Ut non nisi arcu.',
          'Ut diam erat, consequat quis posuere vel, dignissim id lectus. Aliquam sollicitudin ante ut diam convallis accumsan. Curabitur eu sapien vitae enim imperdiet tempus. Aliquam iaculis sodales consequat. Maecenas faucibus sem ut facilisis pulvinar. Mauris pulvinar diam eget eros suscipit convallis sed molestie erat. Ut tristique suscipit turpis, eu tempus turpis volutpat non. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nullam blandit, libero quis sagittis accumsan, ligula odio tristique ex, eleifend blandit urna justo sit amet ante. Mauris non vestibulum turpis.',
          'Nam congue molestie libero sed bibendum. Fusce posuere eros ac mauris dapibus pharetra eu convallis felis. Etiam pellentesque ultricies rhoncus. Duis fermentum orci sem, nec tincidunt nibh viverra eu. Aenean dictum accumsan dictum. Nulla sit amet ipsum quam. Cras eu neque maximus, vulputate diam eu, consequat enim. Aliquam erat volutpat. Phasellus et rutrum magna.',
          'Vestibulum blandit nunc non sollicitudin imperdiet. In ultrices mauris eu nisl molestie elementum. In vel erat enim. Duis non magna sem. Quisque et egestas neque, vitae fermentum ante. Maecenas venenatis nibh eu facilisis egestas. Praesent iaculis rhoncus sodales. Aenean feugiat lectus nec tortor bibendum lobortis. Quisque velit nulla, pellentesque ac accumsan sed, sollicitudin id urna. Fusce ut dui urna. Phasellus dictum eget arcu vel tempor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi gravida non dolor quis aliquam. In hac habitasse platea dictumst. Mauris convallis ex at massa finibus, ac feugiat diam vestibulum. Phasellus ultricies est orci, suscipit elementum arcu dictum at.',
          'Sed quis tortor urna. Cras et eleifend arcu, nec semper sapien. Nunc vel facilisis turpis. Donec ullamcorper augue at nunc sollicitudin, quis consectetur velit accumsan. Sed quis pretium felis. Pellentesque consequat est a tempor ullamcorper. Quisque ullamcorper nisl in nisl laoreet, id tincidunt ligula posuere. Nam in auctor nibh. Nunc lobortis posuere tellus, malesuada posuere mi tempus eget. Quisque ut magna non risus faucibus commodo. In hac habitasse platea dictumst. Cras ut massa nisi.',
        ],
        thumbnailUrl:
          'https://stablo-pro.web3templates.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fcijrdavx%2Fproduction%2Fa5bd8977e7fd82666c00a45948583b1f9912d392-3847x5583.jpg%3Fw%3D2000%26auto%3Dformat&w=1200&q=75',
        thumbnailColor: '#2770cf',
        thumbnailAlt: 'Thumbnail',
      },
      {
        title: `Nulla sit amet ipsum quam`,
        slug: 'nulla-sit-amet-ipsum-quam',
        paragraphs: [
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed nisi mauris. Sed finibus nec dui in scelerisque. Aliquam a convallis leo, id blandit nisi. Phasellus dui libero, cursus in dui a, congue sagittis metus. Integer posuere lacus ut efficitur porttitor. Duis in nulla eu risus eleifend egestas. Donec viverra ultrices aliquet. Suspendisse facilisis quis ante ac commodo. Donec bibendum sodales purus non vehicula. Quisque dignissim augue ut ipsum tincidunt, id efficitur nibh feugiat. Ut non nisi arcu.',
          'Ut diam erat, consequat quis posuere vel, dignissim id lectus. Aliquam sollicitudin ante ut diam convallis accumsan. Curabitur eu sapien vitae enim imperdiet tempus. Aliquam iaculis sodales consequat. Maecenas faucibus sem ut facilisis pulvinar. Mauris pulvinar diam eget eros suscipit convallis sed molestie erat. Ut tristique suscipit turpis, eu tempus turpis volutpat non. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nullam blandit, libero quis sagittis accumsan, ligula odio tristique ex, eleifend blandit urna justo sit amet ante. Mauris non vestibulum turpis.',
          'Nam congue molestie libero sed bibendum. Fusce posuere eros ac mauris dapibus pharetra eu convallis felis. Etiam pellentesque ultricies rhoncus. Duis fermentum orci sem, nec tincidunt nibh viverra eu. Aenean dictum accumsan dictum. Nulla sit amet ipsum quam. Cras eu neque maximus, vulputate diam eu, consequat enim. Aliquam erat volutpat. Phasellus et rutrum magna.',
          'Vestibulum blandit nunc non sollicitudin imperdiet. In ultrices mauris eu nisl molestie elementum. In vel erat enim. Duis non magna sem. Quisque et egestas neque, vitae fermentum ante. Maecenas venenatis nibh eu facilisis egestas. Praesent iaculis rhoncus sodales. Aenean feugiat lectus nec tortor bibendum lobortis. Quisque velit nulla, pellentesque ac accumsan sed, sollicitudin id urna. Fusce ut dui urna. Phasellus dictum eget arcu vel tempor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi gravida non dolor quis aliquam. In hac habitasse platea dictumst. Mauris convallis ex at massa finibus, ac feugiat diam vestibulum. Phasellus ultricies est orci, suscipit elementum arcu dictum at.',
          'Sed quis tortor urna. Cras et eleifend arcu, nec semper sapien. Nunc vel facilisis turpis. Donec ullamcorper augue at nunc sollicitudin, quis consectetur velit accumsan. Sed quis pretium felis. Pellentesque consequat est a tempor ullamcorper. Quisque ullamcorper nisl in nisl laoreet, id tincidunt ligula posuere. Nam in auctor nibh. Nunc lobortis posuere tellus, malesuada posuere mi tempus eget. Quisque ut magna non risus faucibus commodo. In hac habitasse platea dictumst. Cras ut massa nisi.',
        ],
        thumbnailUrl:
          'https://stablo-pro.web3templates.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fcijrdavx%2Fproduction%2F279c03681911845947cd044b4ac9e91d7a3a628c-987x1481.png%3Fw%3D987%26auto%3Dformat&w=1200&q=75',
        thumbnailColor: '#2770cf',
        thumbnailAlt: 'Thumbnail',
      },
      {
        title: `Cras eu neque maximus, vulputate diam eu, consequat enim`,
        slug: 'cras-eu-neque-maximus-vulputate-diam-eu-consequat-enim',
        paragraphs: [
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed nisi mauris. Sed finibus nec dui in scelerisque. Aliquam a convallis leo, id blandit nisi. Phasellus dui libero, cursus in dui a, congue sagittis metus. Integer posuere lacus ut efficitur porttitor. Duis in nulla eu risus eleifend egestas. Donec viverra ultrices aliquet. Suspendisse facilisis quis ante ac commodo. Donec bibendum sodales purus non vehicula. Quisque dignissim augue ut ipsum tincidunt, id efficitur nibh feugiat. Ut non nisi arcu.',
          'Ut diam erat, consequat quis posuere vel, dignissim id lectus. Aliquam sollicitudin ante ut diam convallis accumsan. Curabitur eu sapien vitae enim imperdiet tempus. Aliquam iaculis sodales consequat. Maecenas faucibus sem ut facilisis pulvinar. Mauris pulvinar diam eget eros suscipit convallis sed molestie erat. Ut tristique suscipit turpis, eu tempus turpis volutpat non. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nullam blandit, libero quis sagittis accumsan, ligula odio tristique ex, eleifend blandit urna justo sit amet ante. Mauris non vestibulum turpis.',
          'Nam congue molestie libero sed bibendum. Fusce posuere eros ac mauris dapibus pharetra eu convallis felis. Etiam pellentesque ultricies rhoncus. Duis fermentum orci sem, nec tincidunt nibh viverra eu. Aenean dictum accumsan dictum. Nulla sit amet ipsum quam. Cras eu neque maximus, vulputate diam eu, consequat enim. Aliquam erat volutpat. Phasellus et rutrum magna.',
          'Vestibulum blandit nunc non sollicitudin imperdiet. In ultrices mauris eu nisl molestie elementum. In vel erat enim. Duis non magna sem. Quisque et egestas neque, vitae fermentum ante. Maecenas venenatis nibh eu facilisis egestas. Praesent iaculis rhoncus sodales. Aenean feugiat lectus nec tortor bibendum lobortis. Quisque velit nulla, pellentesque ac accumsan sed, sollicitudin id urna. Fusce ut dui urna. Phasellus dictum eget arcu vel tempor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi gravida non dolor quis aliquam. In hac habitasse platea dictumst. Mauris convallis ex at massa finibus, ac feugiat diam vestibulum. Phasellus ultricies est orci, suscipit elementum arcu dictum at.',
          'Sed quis tortor urna. Cras et eleifend arcu, nec semper sapien. Nunc vel facilisis turpis. Donec ullamcorper augue at nunc sollicitudin, quis consectetur velit accumsan. Sed quis pretium felis. Pellentesque consequat est a tempor ullamcorper. Quisque ullamcorper nisl in nisl laoreet, id tincidunt ligula posuere. Nam in auctor nibh. Nunc lobortis posuere tellus, malesuada posuere mi tempus eget. Quisque ut magna non risus faucibus commodo. In hac habitasse platea dictumst. Cras ut massa nisi.',
        ],
        thumbnailUrl:
          'https://stablo-pro.web3templates.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fcijrdavx%2Fproduction%2F123fb8836aaf097b83d4c5e8fde544dd1747af0d-5064x3376.jpg%3Fw%3D2000%26auto%3Dformat&w=1200&q=75',
        thumbnailColor: '#2770cf',
        thumbnailAlt: 'Thumbnail',
      },
    ],
  },
  {
    name: 'Entertainment',
    slug: 'entertainment',
    description:
      'Reviews of movies, music, books, and other entertainment content.',
    color: 'orange',
    subject: 'entertainment',
    posts: [
      {
        title: `Lorem ipsum dolor sit amet, consectetur adipiscing elit`,
        slug: 'lorem-ipsum-dolor-sit-amet',
        paragraphs: [
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed nisi mauris. Sed finibus nec dui in scelerisque. Aliquam a convallis leo, id blandit nisi. Phasellus dui libero, cursus in dui a, congue sagittis metus. Integer posuere lacus ut efficitur porttitor. Duis in nulla eu risus eleifend egestas. Donec viverra ultrices aliquet. Suspendisse facilisis quis ante ac commodo. Donec bibendum sodales purus non vehicula. Quisque dignissim augue ut ipsum tincidunt, id efficitur nibh feugiat. Ut non nisi arcu.',
          'Ut diam erat, consequat quis posuere vel, dignissim id lectus. Aliquam sollicitudin ante ut diam convallis accumsan. Curabitur eu sapien vitae enim imperdiet tempus. Aliquam iaculis sodales consequat. Maecenas faucibus sem ut facilisis pulvinar. Mauris pulvinar diam eget eros suscipit convallis sed molestie erat. Ut tristique suscipit turpis, eu tempus turpis volutpat non. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nullam blandit, libero quis sagittis accumsan, ligula odio tristique ex, eleifend blandit urna justo sit amet ante. Mauris non vestibulum turpis.',
          'Nam congue molestie libero sed bibendum. Fusce posuere eros ac mauris dapibus pharetra eu convallis felis. Etiam pellentesque ultricies rhoncus. Duis fermentum orci sem, nec tincidunt nibh viverra eu. Aenean dictum accumsan dictum. Nulla sit amet ipsum quam. Cras eu neque maximus, vulputate diam eu, consequat enim. Aliquam erat volutpat. Phasellus et rutrum magna.',
          'Vestibulum blandit nunc non sollicitudin imperdiet. In ultrices mauris eu nisl molestie elementum. In vel erat enim. Duis non magna sem. Quisque et egestas neque, vitae fermentum ante. Maecenas venenatis nibh eu facilisis egestas. Praesent iaculis rhoncus sodales. Aenean feugiat lectus nec tortor bibendum lobortis. Quisque velit nulla, pellentesque ac accumsan sed, sollicitudin id urna. Fusce ut dui urna. Phasellus dictum eget arcu vel tempor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi gravida non dolor quis aliquam. In hac habitasse platea dictumst. Mauris convallis ex at massa finibus, ac feugiat diam vestibulum. Phasellus ultricies est orci, suscipit elementum arcu dictum at.',
          'Sed quis tortor urna. Cras et eleifend arcu, nec semper sapien. Nunc vel facilisis turpis. Donec ullamcorper augue at nunc sollicitudin, quis consectetur velit accumsan. Sed quis pretium felis. Pellentesque consequat est a tempor ullamcorper. Quisque ullamcorper nisl in nisl laoreet, id tincidunt ligula posuere. Nam in auctor nibh. Nunc lobortis posuere tellus, malesuada posuere mi tempus eget. Quisque ut magna non risus faucibus commodo. In hac habitasse platea dictumst. Cras ut massa nisi.',
        ],
        thumbnailUrl:
          'https://stablo-pro.web3templates.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fcijrdavx%2Fproduction%2F05951a0ec1a6ffc54f615ab160649e92fea982d0-800x764.png%3Frect%3D0%2C0%2C800%2C468%26w%3D800%26auto%3Dformat&w=1200&q=75',
        thumbnailColor: '#2770cf',
        thumbnailAlt: 'Thumbnail',
        featured: true,
      },
      {
        title: `Sed sit amet nulla lacus`,
        slug: 'sed-sit-amet-nulla-lacus',
        paragraphs: [
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed nisi mauris. Sed finibus nec dui in scelerisque. Aliquam a convallis leo, id blandit nisi. Phasellus dui libero, cursus in dui a, congue sagittis metus. Integer posuere lacus ut efficitur porttitor. Duis in nulla eu risus eleifend egestas. Donec viverra ultrices aliquet. Suspendisse facilisis quis ante ac commodo. Donec bibendum sodales purus non vehicula. Quisque dignissim augue ut ipsum tincidunt, id efficitur nibh feugiat. Ut non nisi arcu.',
          'Ut diam erat, consequat quis posuere vel, dignissim id lectus. Aliquam sollicitudin ante ut diam convallis accumsan. Curabitur eu sapien vitae enim imperdiet tempus. Aliquam iaculis sodales consequat. Maecenas faucibus sem ut facilisis pulvinar. Mauris pulvinar diam eget eros suscipit convallis sed molestie erat. Ut tristique suscipit turpis, eu tempus turpis volutpat non. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nullam blandit, libero quis sagittis accumsan, ligula odio tristique ex, eleifend blandit urna justo sit amet ante. Mauris non vestibulum turpis.',
          'Nam congue molestie libero sed bibendum. Fusce posuere eros ac mauris dapibus pharetra eu convallis felis. Etiam pellentesque ultricies rhoncus. Duis fermentum orci sem, nec tincidunt nibh viverra eu. Aenean dictum accumsan dictum. Nulla sit amet ipsum quam. Cras eu neque maximus, vulputate diam eu, consequat enim. Aliquam erat volutpat. Phasellus et rutrum magna.',
          'Vestibulum blandit nunc non sollicitudin imperdiet. In ultrices mauris eu nisl molestie elementum. In vel erat enim. Duis non magna sem. Quisque et egestas neque, vitae fermentum ante. Maecenas venenatis nibh eu facilisis egestas. Praesent iaculis rhoncus sodales. Aenean feugiat lectus nec tortor bibendum lobortis. Quisque velit nulla, pellentesque ac accumsan sed, sollicitudin id urna. Fusce ut dui urna. Phasellus dictum eget arcu vel tempor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi gravida non dolor quis aliquam. In hac habitasse platea dictumst. Mauris convallis ex at massa finibus, ac feugiat diam vestibulum. Phasellus ultricies est orci, suscipit elementum arcu dictum at.',
          'Sed quis tortor urna. Cras et eleifend arcu, nec semper sapien. Nunc vel facilisis turpis. Donec ullamcorper augue at nunc sollicitudin, quis consectetur velit accumsan. Sed quis pretium felis. Pellentesque consequat est a tempor ullamcorper. Quisque ullamcorper nisl in nisl laoreet, id tincidunt ligula posuere. Nam in auctor nibh. Nunc lobortis posuere tellus, malesuada posuere mi tempus eget. Quisque ut magna non risus faucibus commodo. In hac habitasse platea dictumst. Cras ut massa nisi.',
        ],
        thumbnailUrl:
          'https://stablo-pro.web3templates.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fcijrdavx%2Fproduction%2F2fda477a7e32f813abb9a8ef425939e6a91c7973-987x1481.png%3Frect%3D0%2C279%2C987%2C607%26w%3D987%26auto%3Dformat&w=1200&q=75',
        thumbnailColor: '#2770cf',
        thumbnailAlt: 'Thumbnail',
      },
      {
        title: `Fusce posuere eros ac mauris dapibus pharetra`,
        slug: 'fusce-posuere-eros-ac-mauris-dapibus-pharetra',
        paragraphs: [
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed nisi mauris. Sed finibus nec dui in scelerisque. Aliquam a convallis leo, id blandit nisi. Phasellus dui libero, cursus in dui a, congue sagittis metus. Integer posuere lacus ut efficitur porttitor. Duis in nulla eu risus eleifend egestas. Donec viverra ultrices aliquet. Suspendisse facilisis quis ante ac commodo. Donec bibendum sodales purus non vehicula. Quisque dignissim augue ut ipsum tincidunt, id efficitur nibh feugiat. Ut non nisi arcu.',
          'Ut diam erat, consequat quis posuere vel, dignissim id lectus. Aliquam sollicitudin ante ut diam convallis accumsan. Curabitur eu sapien vitae enim imperdiet tempus. Aliquam iaculis sodales consequat. Maecenas faucibus sem ut facilisis pulvinar. Mauris pulvinar diam eget eros suscipit convallis sed molestie erat. Ut tristique suscipit turpis, eu tempus turpis volutpat non. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nullam blandit, libero quis sagittis accumsan, ligula odio tristique ex, eleifend blandit urna justo sit amet ante. Mauris non vestibulum turpis.',
          'Nam congue molestie libero sed bibendum. Fusce posuere eros ac mauris dapibus pharetra eu convallis felis. Etiam pellentesque ultricies rhoncus. Duis fermentum orci sem, nec tincidunt nibh viverra eu. Aenean dictum accumsan dictum. Nulla sit amet ipsum quam. Cras eu neque maximus, vulputate diam eu, consequat enim. Aliquam erat volutpat. Phasellus et rutrum magna.',
          'Vestibulum blandit nunc non sollicitudin imperdiet. In ultrices mauris eu nisl molestie elementum. In vel erat enim. Duis non magna sem. Quisque et egestas neque, vitae fermentum ante. Maecenas venenatis nibh eu facilisis egestas. Praesent iaculis rhoncus sodales. Aenean feugiat lectus nec tortor bibendum lobortis. Quisque velit nulla, pellentesque ac accumsan sed, sollicitudin id urna. Fusce ut dui urna. Phasellus dictum eget arcu vel tempor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi gravida non dolor quis aliquam. In hac habitasse platea dictumst. Mauris convallis ex at massa finibus, ac feugiat diam vestibulum. Phasellus ultricies est orci, suscipit elementum arcu dictum at.',
          'Sed quis tortor urna. Cras et eleifend arcu, nec semper sapien. Nunc vel facilisis turpis. Donec ullamcorper augue at nunc sollicitudin, quis consectetur velit accumsan. Sed quis pretium felis. Pellentesque consequat est a tempor ullamcorper. Quisque ullamcorper nisl in nisl laoreet, id tincidunt ligula posuere. Nam in auctor nibh. Nunc lobortis posuere tellus, malesuada posuere mi tempus eget. Quisque ut magna non risus faucibus commodo. In hac habitasse platea dictumst. Cras ut massa nisi.',
        ],
        thumbnailUrl:
          'https://stablo-pro.web3templates.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fcijrdavx%2Fproduction%2Fa5bd8977e7fd82666c00a45948583b1f9912d392-3847x5583.jpg%3Fw%3D2000%26auto%3Dformat&w=1200&q=75',
        thumbnailColor: '#2770cf',
        thumbnailAlt: 'Thumbnail',
      },
      {
        title: `Nulla sit amet ipsum quam`,
        slug: 'nulla-sit-amet-ipsum-quam',
        paragraphs: [
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed nisi mauris. Sed finibus nec dui in scelerisque. Aliquam a convallis leo, id blandit nisi. Phasellus dui libero, cursus in dui a, congue sagittis metus. Integer posuere lacus ut efficitur porttitor. Duis in nulla eu risus eleifend egestas. Donec viverra ultrices aliquet. Suspendisse facilisis quis ante ac commodo. Donec bibendum sodales purus non vehicula. Quisque dignissim augue ut ipsum tincidunt, id efficitur nibh feugiat. Ut non nisi arcu.',
          'Ut diam erat, consequat quis posuere vel, dignissim id lectus. Aliquam sollicitudin ante ut diam convallis accumsan. Curabitur eu sapien vitae enim imperdiet tempus. Aliquam iaculis sodales consequat. Maecenas faucibus sem ut facilisis pulvinar. Mauris pulvinar diam eget eros suscipit convallis sed molestie erat. Ut tristique suscipit turpis, eu tempus turpis volutpat non. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nullam blandit, libero quis sagittis accumsan, ligula odio tristique ex, eleifend blandit urna justo sit amet ante. Mauris non vestibulum turpis.',
          'Nam congue molestie libero sed bibendum. Fusce posuere eros ac mauris dapibus pharetra eu convallis felis. Etiam pellentesque ultricies rhoncus. Duis fermentum orci sem, nec tincidunt nibh viverra eu. Aenean dictum accumsan dictum. Nulla sit amet ipsum quam. Cras eu neque maximus, vulputate diam eu, consequat enim. Aliquam erat volutpat. Phasellus et rutrum magna.',
          'Vestibulum blandit nunc non sollicitudin imperdiet. In ultrices mauris eu nisl molestie elementum. In vel erat enim. Duis non magna sem. Quisque et egestas neque, vitae fermentum ante. Maecenas venenatis nibh eu facilisis egestas. Praesent iaculis rhoncus sodales. Aenean feugiat lectus nec tortor bibendum lobortis. Quisque velit nulla, pellentesque ac accumsan sed, sollicitudin id urna. Fusce ut dui urna. Phasellus dictum eget arcu vel tempor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi gravida non dolor quis aliquam. In hac habitasse platea dictumst. Mauris convallis ex at massa finibus, ac feugiat diam vestibulum. Phasellus ultricies est orci, suscipit elementum arcu dictum at.',
          'Sed quis tortor urna. Cras et eleifend arcu, nec semper sapien. Nunc vel facilisis turpis. Donec ullamcorper augue at nunc sollicitudin, quis consectetur velit accumsan. Sed quis pretium felis. Pellentesque consequat est a tempor ullamcorper. Quisque ullamcorper nisl in nisl laoreet, id tincidunt ligula posuere. Nam in auctor nibh. Nunc lobortis posuere tellus, malesuada posuere mi tempus eget. Quisque ut magna non risus faucibus commodo. In hac habitasse platea dictumst. Cras ut massa nisi.',
        ],
        thumbnailUrl:
          'https://stablo-pro.web3templates.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fcijrdavx%2Fproduction%2F279c03681911845947cd044b4ac9e91d7a3a628c-987x1481.png%3Fw%3D987%26auto%3Dformat&w=1200&q=75',
        thumbnailColor: '#2770cf',
        thumbnailAlt: 'Thumbnail',
      },
      {
        title: `Cras eu neque maximus, vulputate diam eu, consequat enim`,
        slug: 'cras-eu-neque-maximus-vulputate-diam-eu-consequat-enim',
        paragraphs: [
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed nisi mauris. Sed finibus nec dui in scelerisque. Aliquam a convallis leo, id blandit nisi. Phasellus dui libero, cursus in dui a, congue sagittis metus. Integer posuere lacus ut efficitur porttitor. Duis in nulla eu risus eleifend egestas. Donec viverra ultrices aliquet. Suspendisse facilisis quis ante ac commodo. Donec bibendum sodales purus non vehicula. Quisque dignissim augue ut ipsum tincidunt, id efficitur nibh feugiat. Ut non nisi arcu.',
          'Ut diam erat, consequat quis posuere vel, dignissim id lectus. Aliquam sollicitudin ante ut diam convallis accumsan. Curabitur eu sapien vitae enim imperdiet tempus. Aliquam iaculis sodales consequat. Maecenas faucibus sem ut facilisis pulvinar. Mauris pulvinar diam eget eros suscipit convallis sed molestie erat. Ut tristique suscipit turpis, eu tempus turpis volutpat non. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nullam blandit, libero quis sagittis accumsan, ligula odio tristique ex, eleifend blandit urna justo sit amet ante. Mauris non vestibulum turpis.',
          'Nam congue molestie libero sed bibendum. Fusce posuere eros ac mauris dapibus pharetra eu convallis felis. Etiam pellentesque ultricies rhoncus. Duis fermentum orci sem, nec tincidunt nibh viverra eu. Aenean dictum accumsan dictum. Nulla sit amet ipsum quam. Cras eu neque maximus, vulputate diam eu, consequat enim. Aliquam erat volutpat. Phasellus et rutrum magna.',
          'Vestibulum blandit nunc non sollicitudin imperdiet. In ultrices mauris eu nisl molestie elementum. In vel erat enim. Duis non magna sem. Quisque et egestas neque, vitae fermentum ante. Maecenas venenatis nibh eu facilisis egestas. Praesent iaculis rhoncus sodales. Aenean feugiat lectus nec tortor bibendum lobortis. Quisque velit nulla, pellentesque ac accumsan sed, sollicitudin id urna. Fusce ut dui urna. Phasellus dictum eget arcu vel tempor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi gravida non dolor quis aliquam. In hac habitasse platea dictumst. Mauris convallis ex at massa finibus, ac feugiat diam vestibulum. Phasellus ultricies est orci, suscipit elementum arcu dictum at.',
          'Sed quis tortor urna. Cras et eleifend arcu, nec semper sapien. Nunc vel facilisis turpis. Donec ullamcorper augue at nunc sollicitudin, quis consectetur velit accumsan. Sed quis pretium felis. Pellentesque consequat est a tempor ullamcorper. Quisque ullamcorper nisl in nisl laoreet, id tincidunt ligula posuere. Nam in auctor nibh. Nunc lobortis posuere tellus, malesuada posuere mi tempus eget. Quisque ut magna non risus faucibus commodo. In hac habitasse platea dictumst. Cras ut massa nisi.',
        ],
        thumbnailUrl:
          'https://stablo-pro.web3templates.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fcijrdavx%2Fproduction%2F123fb8836aaf097b83d4c5e8fde544dd1747af0d-5064x3376.jpg%3Fw%3D2000%26auto%3Dformat&w=1200&q=75',
        thumbnailColor: '#2770cf',
        thumbnailAlt: 'Thumbnail',
      },
      {
        title: `Aliquam erat volutpat`,
        slug: 'aliquam-erat-volutpat',
        paragraphs: [
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed nisi mauris. Sed finibus nec dui in scelerisque. Aliquam a convallis leo, id blandit nisi. Phasellus dui libero, cursus in dui a, congue sagittis metus. Integer posuere lacus ut efficitur porttitor. Duis in nulla eu risus eleifend egestas. Donec viverra ultrices aliquet. Suspendisse facilisis quis ante ac commodo. Donec bibendum sodales purus non vehicula. Quisque dignissim augue ut ipsum tincidunt, id efficitur nibh feugiat. Ut non nisi arcu.',
          'Ut diam erat, consequat quis posuere vel, dignissim id lectus. Aliquam sollicitudin ante ut diam convallis accumsan. Curabitur eu sapien vitae enim imperdiet tempus. Aliquam iaculis sodales consequat. Maecenas faucibus sem ut facilisis pulvinar. Mauris pulvinar diam eget eros suscipit convallis sed molestie erat. Ut tristique suscipit turpis, eu tempus turpis volutpat non. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nullam blandit, libero quis sagittis accumsan, ligula odio tristique ex, eleifend blandit urna justo sit amet ante. Mauris non vestibulum turpis.',
          'Nam congue molestie libero sed bibendum. Fusce posuere eros ac mauris dapibus pharetra eu convallis felis. Etiam pellentesque ultricies rhoncus. Duis fermentum orci sem, nec tincidunt nibh viverra eu. Aenean dictum accumsan dictum. Nulla sit amet ipsum quam. Cras eu neque maximus, vulputate diam eu, consequat enim. Aliquam erat volutpat. Phasellus et rutrum magna.',
          'Vestibulum blandit nunc non sollicitudin imperdiet. In ultrices mauris eu nisl molestie elementum. In vel erat enim. Duis non magna sem. Quisque et egestas neque, vitae fermentum ante. Maecenas venenatis nibh eu facilisis egestas. Praesent iaculis rhoncus sodales. Aenean feugiat lectus nec tortor bibendum lobortis. Quisque velit nulla, pellentesque ac accumsan sed, sollicitudin id urna. Fusce ut dui urna. Phasellus dictum eget arcu vel tempor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi gravida non dolor quis aliquam. In hac habitasse platea dictumst. Mauris convallis ex at massa finibus, ac feugiat diam vestibulum. Phasellus ultricies est orci, suscipit elementum arcu dictum at.',
          'Sed quis tortor urna. Cras et eleifend arcu, nec semper sapien. Nunc vel facilisis turpis. Donec ullamcorper augue at nunc sollicitudin, quis consectetur velit accumsan. Sed quis pretium felis. Pellentesque consequat est a tempor ullamcorper. Quisque ullamcorper nisl in nisl laoreet, id tincidunt ligula posuere. Nam in auctor nibh. Nunc lobortis posuere tellus, malesuada posuere mi tempus eget. Quisque ut magna non risus faucibus commodo. In hac habitasse platea dictumst. Cras ut massa nisi.',
        ],
        thumbnailUrl:
          'https://stablo-pro.web3templates.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fcijrdavx%2Fproduction%2F5be1635417f1814b3fb09f8e9f74f37079899f72-4032x3024.jpg%3Fw%3D2000%26auto%3Dformat&w=1200&q=75',
        thumbnailColor: '#2770cf',
        thumbnailAlt: 'Thumbnail',
      },
      {
        title: `Phasellus et rutrum magna`,
        slug: 'phasellus-et-rutrum-magna',
        paragraphs: [
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed nisi mauris. Sed finibus nec dui in scelerisque. Aliquam a convallis leo, id blandit nisi. Phasellus dui libero, cursus in dui a, congue sagittis metus. Integer posuere lacus ut efficitur porttitor. Duis in nulla eu risus eleifend egestas. Donec viverra ultrices aliquet. Suspendisse facilisis quis ante ac commodo. Donec bibendum sodales purus non vehicula. Quisque dignissim augue ut ipsum tincidunt, id efficitur nibh feugiat. Ut non nisi arcu.',
          'Ut diam erat, consequat quis posuere vel, dignissim id lectus. Aliquam sollicitudin ante ut diam convallis accumsan. Curabitur eu sapien vitae enim imperdiet tempus. Aliquam iaculis sodales consequat. Maecenas faucibus sem ut facilisis pulvinar. Mauris pulvinar diam eget eros suscipit convallis sed molestie erat. Ut tristique suscipit turpis, eu tempus turpis volutpat non. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nullam blandit, libero quis sagittis accumsan, ligula odio tristique ex, eleifend blandit urna justo sit amet ante. Mauris non vestibulum turpis.',
          'Nam congue molestie libero sed bibendum. Fusce posuere eros ac mauris dapibus pharetra eu convallis felis. Etiam pellentesque ultricies rhoncus. Duis fermentum orci sem, nec tincidunt nibh viverra eu. Aenean dictum accumsan dictum. Nulla sit amet ipsum quam. Cras eu neque maximus, vulputate diam eu, consequat enim. Aliquam erat volutpat. Phasellus et rutrum magna.',
          'Vestibulum blandit nunc non sollicitudin imperdiet. In ultrices mauris eu nisl molestie elementum. In vel erat enim. Duis non magna sem. Quisque et egestas neque, vitae fermentum ante. Maecenas venenatis nibh eu facilisis egestas. Praesent iaculis rhoncus sodales. Aenean feugiat lectus nec tortor bibendum lobortis. Quisque velit nulla, pellentesque ac accumsan sed, sollicitudin id urna. Fusce ut dui urna. Phasellus dictum eget arcu vel tempor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi gravida non dolor quis aliquam. In hac habitasse platea dictumst. Mauris convallis ex at massa finibus, ac feugiat diam vestibulum. Phasellus ultricies est orci, suscipit elementum arcu dictum at.',
          'Sed quis tortor urna. Cras et eleifend arcu, nec semper sapien. Nunc vel facilisis turpis. Donec ullamcorper augue at nunc sollicitudin, quis consectetur velit accumsan. Sed quis pretium felis. Pellentesque consequat est a tempor ullamcorper. Quisque ullamcorper nisl in nisl laoreet, id tincidunt ligula posuere. Nam in auctor nibh. Nunc lobortis posuere tellus, malesuada posuere mi tempus eget. Quisque ut magna non risus faucibus commodo. In hac habitasse platea dictumst. Cras ut massa nisi.',
        ],
        thumbnailUrl:
          'https://stablo-pro.web3templates.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fcijrdavx%2Fproduction%2Fd4898616e2973584b1b8a0bfdb5c6cf7c36ab012-1064x1330.png%3Fw%3D1064%26auto%3Dformat&w=1200&q=75',
        thumbnailColor: '#2770cf',
        thumbnailAlt: 'Thumbnail',
      },
    ],
  },
]
